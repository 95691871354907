import React from 'react';

import SearchResult from '../Search/SearchResult';
import styles from './styles.module.scss';

function SavedOrders() {
  const savedWorkOrders = localStorage.getItem('savedWorkOrders');
  const results = savedWorkOrders ? JSON.parse(savedWorkOrders) : [];

  return (
    <div className={styles.SavedOrders}>
      <SearchResult resultProp={results} />
    </div>
  );
}

export default SavedOrders;
