import $ from 'jquery';

import { BACKEND_API } from './constants';

const postToAccountInfoBack = (data) => $.ajax({
  method: 'POST',
  url: `${BACKEND_API}/backend/functions/accountInfoBack/accountInfoBack.php`,
  data,
});

export const getContactMethods = (sessionFromReact) => {
  const data = { sessionFromReact, action: 'getContactMethods', UTILITYID: 9 };
  return postToAccountInfoBack(data);
};

export const addContactSubmit = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    ...formData,
    action: 'addContactSubmit',
    UTILITYID: 59,
  };
  return postToAccountInfoBack(data);
};

export const editContactSubmit = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    ...formData,
    action: 'editContactSubmit',
    UTILITYID: 10,
  };
  return postToAccountInfoBack(data);
};

export const linkContactLocations = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    ...formData,
    action: 'linkContactLocations',
    UTILITYID: 59,
  };
  return postToAccountInfoBack(data);
};

export const editContactMethodLocation = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    ...formData,
    action: 'editContactMethodLocation',
    UTILITYID: 59,
  };
  return postToAccountInfoBack(data);
};

export const getContactMethodLinkObject = (sessionFromReact, ContactMethodLinkID) => {
  const data = {
    sessionFromReact,
    ContactMethodLinkID,
    action: 'getContactMethodLinkObject',
    UTILITYID: 59,
  };
  return postToAccountInfoBack(data);
};

export const editContactMethodLinkType = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    ...formData,
    action: 'editContactMethodLinkType',
    UTILITYID: 59,
  };
  return postToAccountInfoBack(data);
};

export const addUser = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    ...formData,
    action: 'addUser',
    UTILITYID: 59,
  };
  return postToAccountInfoBack(data);
};

export const getUsers = (sessionFromReact) => {
  const data = {
    sessionFromReact,
    action: 'getUsers',
    UTILITYID: 59,
  };
  return postToAccountInfoBack(data);
};

export const getPermissionCategories = (sessionFromReact, SelectedUserID) => {
  const data = {
    sessionFromReact,
    SelectedUserID,
    action: 'getPermissionCategories',
    UTILITYID: 59,
  };
  return postToAccountInfoBack(data);
};

export const updateUser = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    ...formData,
    action: 'updateUser',
    UTILITYID: 59,
  };
  return postToAccountInfoBack(data);
};

export const updateusertype = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    ...formData,
    action: 'updateusertype',
    UTILITYID: 59,
  };
  return postToAccountInfoBack(data);
};

export const updatePermissions = (sessionFromReact, UtilityList) => {
  const data = {
    sessionFromReact,
    UtilityList,
    action: 'updatePermissions',
    UTILITYID: 59,
  };
  return postToAccountInfoBack(data);
};
