import React from 'react';
import { BrowserMultiFormatReader, BarcodeFormat, DecodeHintType } from '@zxing/library';
import Webcam from 'react-webcam';

const formats = [
  BarcodeFormat.CODE_128,
  BarcodeFormat.CODE_93,
  BarcodeFormat.CODE_39,
  BarcodeFormat.ITF,
  BarcodeFormat.CODABAR,
  BarcodeFormat.QR_CODE,
];

const hints = new Map();
hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);

function BarcodeScannerComponent({
  audio = false,
  audioConstraints = {},
  forceScreenshotSourceSize = false,
  imageSmoothing = true,
  mirrored = false,
  minScreenshotHeight = 0,
  minScreenshotWidth = 0,
  onUserMedia = () => {},
  onUserMediaError = () => {},
  screenshotFormat = 'image/png',
  screenshotQuality = 0.92,
  videoConstraints = { facingMode: { exact: 'environment' } },
  width,
  height,
  onUpdate,
}) {
  const webcamRef = React.useRef(null);
  const codeReader = new BrowserMultiFormatReader(hints);
  const capture = React.useCallback(
    () => {
      const imageSrc = webcamRef?.current?.getScreenshot();
      if (imageSrc) {
        codeReader.decodeFromImage(undefined, imageSrc).then((result) => {
          onUpdate(null, result);
        }).catch((err) => {
          onUpdate(err);
        });
      }
    },
    [codeReader, onUpdate],
  );

  React.useEffect(() => {
    setInterval(capture, 100);
  }, []);

  return (
    <Webcam
      audio={audio}
      audioConstraints={audioConstraints}
      forceScreenshotSourceSize={forceScreenshotSourceSize}
      imageSmoothing={imageSmoothing}
      mirrored={mirrored}
      minScreenshotHeight={minScreenshotHeight}
      minScreenshotWidth={minScreenshotWidth}
      onUserMedia={onUserMedia}
      onUserMediaError={onUserMediaError}
      screenshotFormat={screenshotFormat}
      screenshotQuality={screenshotQuality}
      videoConstraints={videoConstraints}
      width={width}
      height={height}
      ref={webcamRef}
    />
  );
}

export default BarcodeScannerComponent;
