export const disableInputs = (completed) => {
  const editWOFormModal = document.getElementById('editWOFormModal');
  if (!editWOFormModal) {
    return;
  }
  const inputs = editWOFormModal.getElementsByTagName('input');
  for (let i = 0; i < inputs.length; i += 1) {
    inputs[i].disabled = completed;
  }
  const textareas = editWOFormModal.getElementsByTagName('textarea');
  for (let i = 0; i < textareas.length; i += 1) {
    textareas[i].disabled = completed;
  }
  const selects = editWOFormModal.getElementsByTagName('select');
  for (let i = 0; i < selects.length; i += 1) {
    selects[i].disabled = completed;
  }
  const buttons = editWOFormModal.getElementsByTagName('button');
  for (let i = 0; i < buttons.length; i += 1) {
    buttons[i].disabled = completed;
  }
};
