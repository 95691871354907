import Swal from 'sweetalert2';

// check if all assets are moved when attempting to close a WO
export const preSubmitChecker = (WOComplete, handleSubmit) => {
  if (!WOComplete) {
    handleSubmit();
    return;
  }
  Swal.fire({
    icon: 'warning',
    text: 'Are you sure all assets are moved properly?',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No, let me double check',
    title: 'You are trying to complete this work order',
  }).then((result) => {
    if (result.isConfirmed) {
      handleSubmit();
    }
  });
};
