import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';

import {
  Link,
  useParams,
  Switch,
  Route,
  useLocation,
} from 'react-router-dom';

import {
  getCircuitsFromSO,
  getPhysicalPlantConfig,
  getWorkOrderObject,
  logout,
} from '../../../../utils';

import WorkOrderContext from './WorkOrderContext';
import Loading from '../../../shared/Loading';
import CheckList from '../../../shared/EditWOForm/CheckList';
import Asset from '../../shared/Assets/Asset';
import Attachments from './Attachments/Attachments';
import ArcGISMap from './ArcGISMap/ArcGISMap';
import CircuitStat from './CircuitStats/CircuitStat';
import CircuitStats from './CircuitStats/CircuitStats';
import Contacts from './Contacts/Contacts';
import Directions from './Directions/Directions';
import Notes from './Notes';
import Photo from './Photo/Photo';
import PhysicalPlantConfig from './PhysicalPlantConfig/PhysicalPlantConfig';
import SalesOrder from './SalesOrder';
import Colocations from './Colocations/Colocations';
import SwapONT from './ProccessONT/SwapONT';
import UpgradeONT from './ProccessONT/UpgradeONT';
import ServiceLocation from './ServiceLocation/ServiceLocation';
import WorkOrderHome from './WorkOrderHome';
import ViewPDF from './ViewPDF';

import styles from './styles.module.scss';

function WorkOrder() {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.session);
  const sessionFromReact = JSON.stringify(currentUser);
  const { WOId } = useParams();
  const { Provider } = WorkOrderContext;
  const [WOObj, setWOObj] = useState({});
  const [physicalConfig, setPhysicalConfig] = useState({});
  const [loading, setLoading] = useState(false);
  const [circuits, setCircuits] = useState([]);
  const { pathname } = useLocation();
  const context = {
    WOObj,
    physicalConfig,
    circuits,
  };

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    getWorkOrderObject(sessionFromReact, WOId)
      .then((response) => {
        if (!isMounted) {
          return;
        }
        const parsed = JSON.parse(response);
        if (parsed) {
          setWOObj(parsed);
        } else {
          Swal.fire({
            icon: 'error',
            text: 'Failed to Fetch Data. Check Internet Connection',
          });
        }
      })
      .catch((error) => {
        // session related error
        if (error.status === 401) {
          dispatch(logout(error));
        }
      })
      .then(() => { setLoading(false); });
    return () => { isMounted = false; };
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (!WOObj.SalesOrderDetails) {
      return undefined;
    }
    setLoading(true);
    getPhysicalPlantConfig(sessionFromReact, WOObj.SalesOrderDetails.nOrderID)
      .then((response) => {
        if (!isMounted) {
          return;
        }
        setPhysicalConfig(JSON.parse(response));
      }).catch((error) => {
        // session related error
        if (error.status === 401) {
          dispatch(logout(error));
        }
      })
      .then(() => { setLoading(false); });
    getCircuitsFromSO(sessionFromReact, WOObj.ServiceLocationDetails.ServiceLocationID)
      .then((response) => {
        if (!isMounted) {
          return;
        }
        setCircuits(JSON.parse(response));
      }).catch((error) => {
        // session related error
        if (error.status === 401) {
          dispatch(logout(error));
        }
      })
      .then(() => { setLoading(false); });
    return () => { isMounted = false; };
  }, [WOObj]);

  const { txtNotes } = WOObj?.SalesOrderDetails?.SalesOrderDetails || {};
  const contactMethods = WOObj.ContactMethods || {};

  return (
    <Provider value={context}>
      <div className={styles.WorkOrder}>
        <div className={styles.workOrderButtons}>
          <Link to="/search">
            <button className={styles.button} type="button" onClick={() => {}}>
              Go back to search results
            </button>
          </Link>
          {loading ? <Loading /> : null}
          {pathname === `/search/${WOId}` ? null : (
            <Link to={`/search/${WOId}`}>
              <button className={styles.button} type="button" onClick={() => {}}>
                Go back to WO #
                {WOId}
              </button>
            </Link>
          )}
        </div>
        <Switch>
          <Route path="/search/:WOId/attachments" component={Attachments} />
          <Route path="/search/:WOId/arcGISMap" component={ArcGISMap} />
          <Route path="/search/:WOId/circuitStats/:socketRecordID" component={CircuitStat} />
          <Route path="/search/:WOId/circuitStats" render={() => <CircuitStats circuits={circuits} />} />
          <Route path="/search/:WOId/colocations" component={Colocations} />
          <Route path="/search/:WOId/directions" component={Directions} />
          <Route path="/search/:WOId/notes" render={() => <Notes txtNotes={txtNotes} />} />
          <Route path="/search/:WOId/photo" component={Photo} />
          <Route path="/search/:WOId/physicalPlantConfig" render={() => <PhysicalPlantConfig physicalConfig={physicalConfig} />} />
          <Route
            path="/search/:WOId/contacts"
            render={() => (
              <Contacts contacts={Object.values(contactMethods.contactMethodsArray || {})} />
            )}
          />
          <Route path="/search/:WOId/salesOrder" component={SalesOrder} />
          <Route path="/search/:WOId/swapONT/:ONTId/:serviceLocationId" component={SwapONT} />
          <Route path="/search/:WOId/upgradeONT/:ONTId/:serviceLocationId" component={UpgradeONT} />
          <Route path="/search/:WOId/checkList" render={() => <CheckList WOObj={WOObj} />} />
          <Route path="/search/:WOId/serviceLocation/:nAssetId" render={() => <Asset showActions />} />
          <Route path="/search/:WOId/serviceLocation" render={() => <ServiceLocation location={WOObj.ServiceLocationDetails} />} />
          <Route path="/search/:WOId/viewPDF" component={ViewPDF} />
          <Route path="/search/:WOId" component={WorkOrderHome} />
        </Switch>
      </div>
    </Provider>
  );
}

export default WorkOrder;
