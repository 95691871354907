import $ from 'jquery';
import Swal from 'sweetalert2';
import { BACKEND_API } from './constants';

const postToProgTestBack = async (data) => {
  let result;
  await $.ajax({
    url: `${BACKEND_API}/backend/functions/progTestBack/progTestBack.php`,
    method: 'POST',
    data,
    success: (response) => { result = response; },
  });
  return result;
};

const arrayBufferToB64 = (buffer) => {
  let binaryString = '';
  const bytes = new Uint8Array(buffer);
  bytes.forEach((byte) => {
    binaryString += String.fromCharCode(byte);
  });
  return btoa(binaryString);
};

export const loginProgTest = (username, password) => {
  const data = {
    action: 'login',
    username,
    password,
  };
  return postToProgTestBack(data);
};

const uploadProgTest = (username, fileB64) => {
  const data = {
    action: 'upload',
    username,
    fileB64,
  };
  $.ajax({
    url: `${BACKEND_API}/backend/functions/progTestBack/progTestBack.php`,
    method: 'POST',
    data,
    success: () => {
      Swal.fire(
        'File submitted!',
        'Your test has been submitted. Thank you for your interest in Socket!',
        'success',
      );
      localStorage.removeItem('username');
      localStorage.removeItem('dtSendingTime');
      window.location.replace('/#/programmingTest');
    },
    error: (error) => {
      Swal.fire(
        'File not submitted!',
        'An error occured during file upload! Please check your file and try again.',
        error.responseText || error.statusText,
      );
    },
  });
};

export const getFileToUpload = () => {
  const username = localStorage.getItem('username');
  const fileList = document.getElementById('zipUpload').files;
  const fileReader = new FileReader();
  fileReader.readAsArrayBuffer(fileList[0]);
  fileReader.onload = () => {
    const fileData = fileReader.result;
    const fileB64 = arrayBufferToB64(fileData);
    if (fileList[0].size > 1000000) {
      Swal.fire(
        'File not submitted!',
        'This file is too large! The maximum allowed size is 1MB.',
        'error',
      );
    } else if (fileList[0].name.substring(fileList[0].name.length - 4) !== '.zip') {
      Swal.fire(
        'File not submitted!',
        'This file is not a .zip file! You must submit a .zip file.',
        'error',
      );
    } else {
      uploadProgTest(username, fileB64);
    }
  };
};
