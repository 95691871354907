import React from 'react';

import './styles.scss';

function Loading({ className = '' }) {
  return (
    <div className={`${className} textAlignCenter spinner`} data-cy="Loading">
      <div className="ldsSpinner">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}

export default Loading;
