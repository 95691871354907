import React from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Buffer } from 'buffer';
/*
  import 'core-js/stable';
  import 'regenerator-runtime/runtime';

  With the following settings in .babel.rc, we don't need the imports
  presets: [
    [
      '@babel/preset-env',
      { useBuiltIns: 'usage' },
    ],
*/

import { logoutUser } from './actions';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import configureStore from './store/store';
import { defaultState } from './reducers/rootReducer';

let refreshing;
if (navigator.serviceWorker) {
  navigator.serviceWorker.addEventListener(
    'controllerchange',
    () => {
      if (refreshing) return;
      refreshing = true;
      window.location.reload();
    },
  );
}

document.addEventListener('DOMContentLoaded', () => {
  let store;
  const token = localStorage.getItem('token');

  if (token) {
    // handle old unencrypted token
    try {
      const bufferObj = Buffer.from(token, 'base64');
      const currentUser = JSON.parse(bufferObj.toString('utf8'));
      defaultState.session = { currentUser };
      const preloadedState = defaultState;
      store = configureStore(preloadedState);
      const currentTime = Date.now() / 1000;
      // // TODO: put a .exp property in the response
      if (currentUser.exp < currentTime) {
        store.dispatch(logoutUser());
        window.location.href = '/';
      }
    } catch (e) {
      console.log('token error', e);
      localStorage.removeItem('token');
      store = configureStore({});
    }
  } else {
    store = configureStore({});
  }
  const root = createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <HashRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </HashRouter>
    </React.StrictMode>,
  );
});

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
