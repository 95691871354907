import React, { useState, useEffect, useContext } from 'react';
import Map from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import Graphic from '@arcgis/core/Graphic';
import PictureMarkerSymbol from '@arcgis/core/symbols/PictureMarkerSymbol';

import '@arcgis/core/assets/esri/themes/dark/main.css';

import { createArcGISToken, handleMessage, ARCGIS_ENDPOINT_URL } from '../../../../../utils';
import WorkOrderContext from '../WorkOrderContext';

import styles from './styles.module.scss';

const availableLayers = {
  Addresses: '0',
  ONT: '1',
  Building: '2',
  Installed: '3',
  'Installed as Built': '4',
  'Design Complate': '5',
  Structures: '9',
  'Residential Fiber': '10',
  'Residential Fiber Construction': '11',
  'Residential Fiber Sign Up': '12',
  'Business Fiber': '14',
  Colos: '18',
};

const defaultLayers = {
  ONT: '1',
  Building: '2',
  'Design Complate': '5',
  Structures: '9',
  'Residential Fiber': '10',
  'Residential Fiber Construction': '11',
  'Residential Fiber Sign Up': '12',
};

function ArcGISMap() {
  const { WOObj } = useContext(WorkOrderContext);
  const [map, setMap] = useState(null);
  const [token, setToken] = useState('');
  const [displayedLayers, setDisplayedLayers] = useState(defaultLayers);

  const toggleLayer = (id) => {
    const existingLayer = map.layers.find((ele) => ele.id === id);
    if (existingLayer) {
      map.layers.remove(existingLayer);
    } else {
      const url = `${ARCGIS_ENDPOINT_URL}/arcgis/rest/services/UnityMapping/UnityMapping/MapServer/${id}`;
      const layer = new FeatureLayer(url, {
        opacity: 0.55,
        showLabels: true,
        apiKey: token,
        visible: true,
        outFields: [], // todo: check what we need here
        id,
      });
      map.layers.add(layer);
    }
  };

  const handleLayerSelect = (key) => {
    const newDisplayedLayers = { ...displayedLayers };
    if (key in displayedLayers) {
      delete newDisplayedLayers[key];
    } else {
      newDisplayedLayers[key] = availableLayers[key];
    }
    setDisplayedLayers(newDisplayedLayers);
    toggleLayer(availableLayers[key]);
  };

  const renderLayers = () => {
    const layers = Object.keys(availableLayers).map((k) => (
      <div key={`${k}-input`}>
        <label>
          <input type="checkbox" checked={k in displayedLayers} onChange={() => handleLayerSelect(k)} autoComplete="off" />
          {k}
        </label>
      </div>
    ));
    return (
      <div>
        <h1>Displayed layers:</h1>
        {layers}
      </div>

    );
  };

  useEffect(() => {
    handleMessage('', 'ArcGISMap');
    setMap(new Map({
      basemap: 'osm', // Additional basemap options are: "streets", "satellite", "hybrid", "topo", "gray", "oceans", "osm", "national-geographic".
      center: [parseFloat(WOObj.ServiceLocationDetails?.Longitude, 10),
        parseFloat(WOObj.ServiceLocationDetails?.Latitude, 10)],
      logo: false,
      showAttribution: false,
      slider: true,
      sliderStyle: 'small',
      zoom: 18,
    }));
    if (!token) {
      createArcGISToken()
        .then((t) => {
          setToken(t);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    if (!map) {
      return;
    }
    // TODO: figure out how to clean this code
    // eslint-disable-next-line no-unused-vars
    const view = new MapView({
      container: 'viewDiv',
      map,
      center: [
        parseFloat(WOObj.ServiceLocationDetails?.Longitude, 10),
        parseFloat(WOObj.ServiceLocationDetails?.Latitude, 10),
      ],
      zoom: 13,
    });
    const symbol = new PictureMarkerSymbol({ url: '/assets/images/marker_image/green.png' });

    const geometry = {
      type: 'point',
      longitude: parseFloat(WOObj.ServiceLocationDetails?.Longitude, 10),
      latitude: parseFloat(WOObj.ServiceLocationDetails?.Latitude, 10),
    };

    const symbolGraphic = new Graphic({ geometry, symbol });
    const graphicsLayer = new GraphicsLayer();
    graphicsLayer.add(symbolGraphic);
    map.add(graphicsLayer);
  }, [map]);

  useEffect(() => {
    if (!token) {
      return;
    }
    Object.values(displayedLayers).forEach((id) => {
      const url = `${ARCGIS_ENDPOINT_URL}/arcgis/rest/services/UnityMapping/UnityMapping/MapServer/${id}`;
      const layer = new FeatureLayer(url, {
        opacity: 0.55,
        showLabels: true,
        visible: true,
        apiKey: token,
        outFields: [], // todo: check what we need here
        id,
      });
      map.layers.add(layer);
    });
  }, [token]);

  return (
    <div className={styles.arcGISMap}>
      {renderLayers()}
      <div id="msgDivArcGISMap" />
      <div id="viewDiv" />
    </div>
  );
}

export default ArcGISMap;
