import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  getWorkOrdersAvailableActions,
  getWorkOrdersActionResponse,
  executeWorkOrdersAction,
  handleMessage,
  logout,
  // getAssetEditData,
  // checkAssetTiedToUser,
  initializeSavedAssets,
  saveLocalStorage,
} from '../../../../utils';

import Loading from '../../../shared/Loading';

import styles from './styles.module.scss';

function AssetActions({ vcAssetIdProp, nAssetIdProp, WOIdProp }) {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.session);
  const sessionFromReact = JSON.stringify(currentUser);
  // const [assetEditDataObj, setAssetEditDataObj] = useState({});
  const [actions, setActions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [requestId, setRequestId] = useState('');
  // const [isTiedToEmployee, setIsTiedToEmployee] = useState(false);
  const { WOId = WOIdProp, nAssetId = nAssetIdProp } = useParams();

  const handleActionClick = (nApplicationActionID) => {
    const formData = {};
    formData.ApplicationActionID = nApplicationActionID;
    formData.WorkOrderID = WOId;
    formData.AssetID = vcAssetIdProp;
    // const swalText = 'This asset is not tied to your inventory, please double check or tie it to yourself first';
    // Commenting out for now - user checking appears to be broken. Need to investigate this further.
    // if (!isTiedToEmployee && nApplicationActionID !== '6') {
    //   if (currentUser.nContractor) {
    //     swalText = 'This asset is not in your pool. Please contact the socket warehouse.';
    //   }
    //   Swal.fire({
    //     text: swalText,
    //     icon: 'warning',
    //   })
    //     .then(() => {
    //       getAssetEditData(sessionFromReact, nAssetId || nAssetIdProp)
    //         .then((response) => {
    //           const parsed = JSON.parse(response);
    //           if (parsed) {
    //             setAssetEditDataObj(parsed);
    //           }
    //         });
    //       checkAssetTiedToUser(sessionFromReact, assetEditDataObj.location.nCount, assetEditDataObj.asset.nCount)
    //         .then((res) => {
    //           setIsTiedToEmployee(res === '1');
    //         });
    //     });
    //   return;
    // }
    executeWorkOrdersAction(sessionFromReact, formData)
      .then((response) => {
        const parsed = JSON.parse(response);
        if (parsed.RequestID) {
          setRequestId(parsed.RequestID);
        } else {
          // TODO: Haven't tested yet, need to figure out what's the backend response
          handleMessage(response, 'AssetActions');
        }
      }).catch((error) => {
        // session related error
        if (error.status === 401) {
          dispatch(logout(error));
        }
      });
  };
  const renderActions = () => {
    if (!actions.length) {
      return null;
    }
    // nApplicationActionID = 39: Clear EVC Map action. Could break multiple customers' internet
    // reference: provapp1 functions/api/workorders/application.php#getAvailableActions
    const actionElements = actions.filter((obj) => obj.nApplicationActionID !== '39')
      .map((action) => {
        const {
          nApplicationActionID,
          txtActionDescription,
          vcAction,
        } = action;
        if (currentUser.nContractor && nApplicationActionID === '6') {
          return null;
        }
        // only show action if tied to employee or show tie to my inventory
        // if (!isTiedToEmployee || nApplicationActionID === '6') {
        //   return null;
        // }
        return (
          <div key={`${nApplicationActionID}-${vcAction}-div`} className={styles.WOActionEleDiv}>
            <span>{vcAction}</span>
            <span>{txtActionDescription}</span>
            <span><button type="button" className="button" onClick={() => handleActionClick(nApplicationActionID)}>Take Action</button></span>
          </div>
        );
      });
    return (
      <div className={styles.WOActionDiv}>
        <h2>{`Actions for WO #${WOId}:`}</h2>
        {actionElements}
      </div>
    );
  };

  // useEffect(() => {
  //   let isMounted = true;
  //   if (!(nAssetId || nAssetIdProp)) {
  //     return undefined;
  //   }
  //   setLoading(true);
  //   // setIsTiedToEmployee(false);
  //   // TODO: see if we can clean this logic up
  //   // nAssetId only, can't be used by scanner
  //   // the info is duplicated comparing to getAsset(), we only need response.location.nCount
  //   getAssetEditData(sessionFromReact, nAssetId || nAssetIdProp)
  //     .then((response) => {
  //       if (!isMounted) {
  //         return;
  //       }
  //       const parsed = JSON.parse(response);
  //       if (parsed) {
  //         setAssetEditDataObj(parsed);
  //       }
  //     })
  //     .catch((error) => {
  //       // session related error
  //       if (error.status === 401) {
  //         dispatch(logout(error));
  //       }
  //     })
  //     .then(() => { setLoading(false); });
  //   return () => { isMounted = false; };
  // }, [nAssetIdProp]);

  useEffect(() => {
    let isMounted = true;
    if (!WOId || !vcAssetIdProp) {
      return undefined;
    }
    setLoading(true);
    const savedAssets = initializeSavedAssets();
    savedAssets[vcAssetIdProp || nAssetIdProp] = { WOId, nAssetId };
    getWorkOrdersAvailableActions(sessionFromReact, WOId, vcAssetIdProp) // Takes KET only
      .then((response) => {
        if (!isMounted || !(response?.length > 0)) {
          return;
        }
        const parsed = JSON.parse(response);
        if (parsed) {
          setActions(parsed);
        }
        if (response === null) {
          saveLocalStorage('savedAssets', savedAssets);
        }
      })
      .catch((error) => {
        saveLocalStorage('savedAssets', savedAssets);
        handleMessage('Failed to fetch the asset actions. Could result from bad internet connection. Saved the asset in Saved Assets tab.', 'AssetActions');

        // session related error
        if (error.status === 401) {
          dispatch(logout(error));
        }
      })
      .then(() => { setLoading(false); });
    return () => { isMounted = false; };
  }, [vcAssetIdProp]);

  useEffect(() => {
    if (!requestId) {
      return () => {};
    }
    let isMounted = true;
    setLoading(true);
    handleMessage('', 'AssetActions');
    const formData = {};
    formData.RequestID = requestId;
    formData.WorkOrderID = WOId;
    formData.vcAssetID = vcAssetIdProp;
    getWorkOrdersActionResponse(sessionFromReact, formData)
      .then(() => {
        if (!isMounted) {
          return;
        }
        Swal.fire({
          icon: 'success',
          title: 'Action request recieved',
          text: 'It may take a moment to process',
        })
          .then(() => {
            // getAssetEditData(sessionFromReact, nAssetId || nAssetIdProp)
            //   .then((resp) => {
            //     const parsed = JSON.parse(resp);
            //     if (parsed) {
            //       setAssetEditDataObj(parsed);
            //     }
            //   });
            // // checkAssetTiedToUser(sessionFromReact, assetEditDataObj.location.nCount, assetEditDataObj.asset.nCount)
            // //   .then((res) => {
            // //     setIsTiedToEmployee(res === '1');
            // //   });
          });
      })
      .catch((error) => {
        // session related error
        if (error.status === 401) {
          dispatch(logout(error));
        }
      })
      .then(() => { setLoading(false); });
    return () => { isMounted = false; };
  }, [requestId]);

  // useEffect(() => {
  //   if (!assetEditDataObj.location) {
  //     return;
  //   }
  //   checkAssetTiedToUser(sessionFromReact, assetEditDataObj.location.nCount, assetEditDataObj.asset.nCount)
  //     .then((res) => {
  //       setIsTiedToEmployee(res === '1');
  //     });
  // }, [assetEditDataObj]);

  return (
    <div className="paddingBottom10">
      {loading ? <Loading /> : null}
      {renderActions()}
      <div className={styles.displayMessage} id="msgDivAssetActions" />
    </div>
  );
}

export default AssetActions;
