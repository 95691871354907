import {
  RECEIVE_CURRENT_USER,
  RECEIVE_USER_LOGOUT,
  RECEIVE_ACTIVE_SERVICE_LOCATION,
  RECEIVE_ACTIVE_MAILING_LOCATION,
} from '../actions';

const defaultState = {
  currentUser: {},
};
const sessionReducer = (state = defaultState, action = {}) => {
  const updatedState = { ...state };
  switch (action.type) {
    case RECEIVE_CURRENT_USER: {
      updatedState.currentUser = action.currentUser;
      break;
    }
    case RECEIVE_USER_LOGOUT: {
      updatedState.currentUser = {};
      break;
    }
    case RECEIVE_ACTIVE_SERVICE_LOCATION: {
      updatedState.currentUser.nActiveServiceLocation = action.nActiveServiceLocation;
      break;
    }
    case RECEIVE_ACTIVE_MAILING_LOCATION: {
      updatedState.currentUser.nActiveMailingLocation = action.nActiveMailingLocation;
      break;
    }
    default: {
      return state;
    }
  }
  return updatedState;
};

export default sessionReducer;
