import React, { useState } from 'react';
import Assets from '../../../shared/Assets/Assets';

import styles from './styles.module.scss';

import ScanAsset from '../../../../shared/ScanAsset/ScanAsset';

function ServiceLocation({ location }) {
  const [showAssets, setShowAssets] = useState(false);
  const [showActive, setShowActive] = useState(true);
  const [showScanner, setShowScanner] = useState(false);

  if (!location || !Object.keys(location).length) {
    return <h1>No Service Location found</h1>;
  }
  const {
    // biz loction type
    nOrderID,
    fullAddress,
    // rez location type
    ServiceLocationID,
    Packages,
  } = location;
  const handleShowScanner = () => {
    setShowScanner(!showScanner);
  };

  const handleShowAssets = () => {
    setShowAssets(!showAssets);
  };

  const renderPackages = () => {
    if (!Packages || Object.values(Packages).length === 0) {
      return (
        <div>
          No Location Found
        </div>
      );
    }
    return Object.values(Packages).map((p) => {
      const {
        PackageName,
        StartDate,
        LastInvoiced,
        Active,
        PackageID,
        MasterID,
      } = p;
      if (Active === '0' && showActive) {
        return null;
      }
      return (
        <div key={`${MasterID}-${PackageID}-div`} className={styles.package}>
          <h3 className="bold">Packages:</h3>
          <div className={styles.item}>
            <span>Package Name:</span>
            <span>{PackageName}</span>
          </div>
          <div className={styles.item}>
            <span>Start Date:</span>
            <span>{StartDate}</span>
          </div>
          <div className={styles.item}>
            <span>Last Invoiced:</span>
            <span>{LastInvoiced}</span>
          </div>
        </div>
      );
    });
  };

  return (
    <div className={styles.ServiceLocation}>
      <div className={`${styles.item} ${styles.address}`}>
        <span className="bold">Address:</span>
        <span>{fullAddress}</span>
      </div>
      <div className={styles.item}>
        <button type="button" onClick={handleShowAssets}>
          {showAssets ? 'Hide Assets' : 'Show Assets'}
        </button>
      </div>
      {showAssets ? (
        <Assets serviceLocationId={nOrderID || ServiceLocationID} />
      ) : null}
      <button type="button" onClick={handleShowScanner} className={styles.toggleScanner}>
        {showScanner ? 'Hide scanner' : 'Add an asset'}
      </button>
      {showScanner ? (
        <ScanAsset />
      ) : null}
      <div className={styles.item}>
        <label htmlFor="showActive">Show Active packages only:</label>
        <span>
          <input id="showActive" type="checkbox" checked={showActive} onChange={() => setShowActive(!showActive)} />
        </span>
      </div>
      {renderPackages()}
    </div>
  );
}

export default ServiceLocation;
