import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import debounce from 'lodash.debounce';

import BarcodeScanner from './BarcodeScanner';
import Loading from '../Loading';
import Asset from '../../Installer/shared/Assets/Asset';
import AssetActions from '../../Installer/shared/Assets/AssetActions';

import {
  searchWorkOrders,
  logout,
  handleAddressStr,
  handleMessage,
  fireError,
} from '../../../utils';

import styles from './styles.module.scss';

const positionOptions = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

function ScanAsset() {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.session);
  const { nContractor } = currentUser;
  const sessionFromReact = JSON.stringify(currentUser);
  const [assetIdInput, setAssetIdInput] = useState('');
  const [tempId, setTempId] = useState('');
  const [WO, setWO] = useState([]);
  const [assignedToMe, setAssignedToMe] = useState(true);
  const [loading, setLoading] = useState(false);
  const [nAssetId, setNAssetId] = useState(0);
  const [cams, setCams] = useState([]);
  const [deviceId, setDeviceId] = useState('');
  const [camIdx, setCamIdx] = useState('');

  const { pathname } = useLocation();
  const changeHandler = (e) => {
    setAssetIdInput(e.target.value);
  };
  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 1000),
    [],
  );
  const idMediator = (e) => {
    setTempId(e.target.value.trim());
    debouncedChangeHandler(e);
  };

  const handleCamButtonClick = () => {
    const newCamIdx = (camIdx + 1) % cams.length;
    setCamIdx(newCamIdx);
  };

  const renderCams = () => {
    if (!cams.length) {
      return null;
    }
    const lis = cams.map((c, i) => (
      <li key={`${c.deviceId}-li`}>{`Camera: ${i + 1}`}</li>
    ));
    return (
      <div className={styles.cameraSelector}>
        {`Current selected camera: ${camIdx === '' ? 'none' : camIdx + 1}`}
        <button type="button" className={styles.camToggleBtn} onClick={handleCamButtonClick}>Toggle Camera</button>
        <h3>Cameras detected:</h3>
        <ul className={styles.camList}>
          {lis}
        </ul>
      </div>
    );
  };

  const renderCheckbocks = () => (
    <label htmlFor="assigned" className={styles.assignToMeLabel}>
      <input id="assigned" type="checkbox" disabled={nContractor === 1} checked={assignedToMe} onChange={() => setAssignedToMe(!assignedToMe)} />
      assigned to you
    </label>
  );

  const renderWO = () => {
    if (!WO) {
      return (
        <div>
          No Workorder Found
          {renderCheckbocks()}
        </div>
      );
    }
    const lis = WO.map((ele) => {
      const {
        nCount, // workOrderID
        vcWorkOrderType,
        vcCustomerName,
        vcNextScheduledName, // eventTitle
        vcNextScheduledTime, // eventTime
      } = ele;
      return (
        <li key={`${nCount}-li`} className={styles.WOSearchResult}>
          {loading ? <Loading /> : null}
          <div>{vcNextScheduledName}</div>
          <div>{vcNextScheduledTime}</div>
          <div className={styles.fontsize}>
            {vcCustomerName}
          </div>
          <div>{handleAddressStr(ele)}</div>
          <div className={styles.space}>
            <span>{nCount}</span>
            <span>{vcWorkOrderType}</span>
          </div>
          <AssetActions vcAssetIdProp={assetIdInput} WOIdProp={nCount} nAssetIdProp={nAssetId} />
          <hr />
        </li>
      );
    });
    return (
      <div className={styles.nearbyWO}>
        <h1>Workorders</h1>
        {renderCheckbocks()}
        <ul>
          {lis}
        </ul>
      </div>
    );
  };

  const onSuccess = (pos) => {
    const formData = {};
    let latitude = '';
    let longitude = '';
    // NOTE: it's not as accurate on PC
    const { coords } = pos; // coords: class GeolocationCoordinates
    latitude = coords.latitude;
    longitude = coords.longitude;
    if (!navigator.geolocation) {
      handleMessage("Can't get geolocation library", 'ScanAsset');
      return;
    }
    formData.ByAssignment = '0';
    if (assignedToMe) {
      formData.ByAssignment = '1';
    }
    formData.ShowPendingAssigned = '1';
    formData.Latitude = latitude;
    formData.Longitude = longitude;
    setLoading(true);
    searchWorkOrders(sessionFromReact, formData)
      .then((response) => {
        const parsed = JSON.parse(response);
        if (parsed) {
          setWO(parsed);
        }
      })
      .catch((error) => {
        // session related error
        if (error.status === 401) {
          dispatch(logout(error));
        }
      })
      .then(() => { setLoading(false); });
  };
  const onError = (msg) => {
    handleMessage(msg.message, 'ScanAsset');
  };

  const handleFormClear = () => {
    setAssetIdInput('');
    setTempId('');
  };

  const renderAssetDetailWrapper = () => {
    if (assetIdInput === null) {
      return null;
    }
    return (
      <div className={styles.assetDetailWrapper}>
        <div className={styles.inputValue}>
          <p>
            {`Asset ID: ${assetIdInput}`}
          </p>
          <form className={styles.paddintBottom10}>
            {assetIdInput ? (
              <Asset vcAssetIdProp={assetIdInput} setNAssetId={setNAssetId} showActions />
            ) : null}
            <div className="paddingBottom10">
              <input
                value={tempId}
                onChange={idMediator}
                id="result"
                placeholder="Input Value"
              />
              <input type="button" value="Clear" onClick={handleFormClear} />
            </div>
          </form>
        </div>
        <div className={styles.camera}>
          <div className={styles.animation} />
          <BarcodeScanner
            videoConstraints={{ deviceId }}
            onUpdate={(err, result) => {
              if (result !== undefined) {
                setAssetIdInput(result.text);
              }
            }}
          />
        </div>
        <div id="msgDivScanAsset" />
      </div>
    );
  };

  useEffect(() => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      fireError('enumerateDevices() not supported.');
      return;
    }
    navigator.mediaDevices.enumerateDevices()
      .then((devices) => {
        const camsFound = devices.filter((d) => d.kind === 'videoinput');
        setCams(camsFound);
        if (camsFound.length) {
          setCamIdx(0);
        }
      })
      .catch((err) => {
        fireError(`${err.name}: ${err.message}`);
      });
  }, []);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(onSuccess, onError, positionOptions);
  }, [assignedToMe]);

  useEffect(() => {
    if (camIdx === '') {
      return;
    }
    setDeviceId(cams[camIdx].deviceId);
  }, [camIdx]);

  return (
    <div className={styles.ScanAsset}>
      {/* for debugging purpose only */}
      {/* <button type="button" onClick={handleDisplayDeviceInfo}>Show my platform</button> */}
      {renderCams()}
      {renderAssetDetailWrapper()}
      {pathname === '/scanAsset' ? renderWO() : null}
      <div id="msgDivScanAsset" />
    </div>
  );
}
export default ScanAsset;
