import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import {
  getAsset,
  handleMessage,
  initializeSavedAssets,
  logout,
  saveLocalStorage,
} from '../../../../utils';

import Loading from '../../../shared/Loading';
import AssetActions from './AssetActions';
import WorkOrderContext from '../../Search/WorkOrder/WorkOrderContext';

import styles from './styles.module.scss';

function Asset({
  vcAssetIdProp,
  nAssetIdProp,
  WOIdProp,
  showActions = false,
  setNAssetId = () => {},
}) {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.session);
  const sessionFromReact = JSON.stringify(currentUser);
  const [assetObj, setAssetObj] = useState({});
  const [loading, setLoading] = useState(false);
  const { nAssetId = nAssetIdProp, WOId = WOIdProp } = useParams();
  const { WOObj } = useContext(WorkOrderContext) || {}; // empty asset if not from WO Home

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    // the endpoint can take nAssid or vcAssetId
    const savedAssets = initializeSavedAssets();
    savedAssets[vcAssetIdProp || nAssetIdProp] = { WOId, nAssetId };
    getAsset(sessionFromReact, nAssetId || vcAssetIdProp)
      .then((response) => {
        if (!isMounted) {
          return;
        }
        if (response === null) {
          saveLocalStorage('savedAssets', savedAssets);
          handleMessage('Failed to fetch the asset actions. Could result from bad internet connection. Saved the asset in Saved Assets tab.', 'msgDivAsset');
        }
        const parsed = JSON.parse(response);
        setNAssetId(parsed.nAssetID);
        if (parsed) {
          setAssetObj(parsed);
        }
      })
      .catch((error) => {
        console.log(error);
        saveLocalStorage('savedAssets', savedAssets);
        handleMessage('Failed to fetch the asset actions. Could result from bad internet connection. Saved the asset in Saved Assets tab.', 'msgDivAsset');
        // session related error
        if (error.status === 401) {
          dispatch(logout(error));
        }
      })
      .then(() => {
        if (!isMounted) {
          return;
        }
        setLoading(false);
      });
    return () => { isMounted = false; };
  }, [nAssetId, vcAssetIdProp, nAssetIdProp, WOIdProp]);

  if (!assetObj || !Object.keys(assetObj).length) {
    return (
      <h1 className={styles.displayMessage}>
        No valid asset info fetched
        <div className={styles.displayMessage} id="msgDivAsset" />
      </h1>
    );
  }

  const {
    AssetID, // ex: "NONKET37264", not the numerical id in the URL
    nAssetID,
    vcAssetDescription,
    vcEquipmentDescription,
    vcEquipmentMaker,
    vcModel,
    vcStatus,
    vcWarranty,
    vcSerialNumber,
    vcMACAddress,
    nPorts,
    nSlots,
    nHardwareType,
  } = assetObj;

  const isONT = nHardwareType === '273';

  return (
    <div className={styles.Asset}>
      {loading ? <Loading /> : null}
      <div key={`${AssetID}-${nAssetId}-div`}>
        <div className="paddingBottom10">
          <div>Asset Description:</div>
          <span className={styles.PPCData}>{vcAssetDescription}</span>
        </div>
        <div className="PaddingBottom10 bold">
          <div>Asset Information</div>
          <span className={styles.PPCData}>{vcEquipmentDescription}</span>
        </div>
        <div className="paddingBottom10">
          <div>Make:</div>
          <span className={styles.PPCData}>{vcEquipmentMaker}</span>
        </div>
        <div className="paddingBottom10">
          <div>Model:</div>
          <span className={styles.PPCData}>{vcModel}</span>
        </div>
        <div className="paddingBottom10">
          <div>Status:</div>
          <span className={styles.PPCData}>{vcStatus}</span>
        </div>
        <div className="paddingBottom10">
          <div>Warranty:</div>
          <span className={styles.PPCData}>{vcWarranty}</span>
        </div>
        <div className="paddingBottom10">
          <div>Serial Number:</div>
          <span className={styles.PPCData}>{vcSerialNumber}</span>
        </div>
        <div className="paddingBottom10">
          <div>MAC Address:</div>
          <span className={styles.PPCData}>{vcMACAddress}</span>
        </div>
        <div className="paddingBottom10">
          <div>Ports:</div>
          <span className={styles.PPCData}>{nPorts}</span>
        </div>
        <div className="paddingBottom10">
          <div>Slots:</div>
          <span className={styles.PPCData}>{nSlots}</span>
        </div>
      </div>
      {showActions && WOId ? (
        <AssetActions vcAssetIdProp={AssetID} nAssetIdProp={nAssetID} WOIdProp={WOId} />
      ) : null}
      {isONT && WOObj ? (
        <>
          <Link to={`/search/${WOId}/swapONT/${nAssetID}/${WOObj.nServiceLocationID}`}>
            <button className="button" type="button" onClick={() => {}}>
              Swap ONT
            </button>
          </Link>
          <Link to={`/search/${WOId}/upgradeONT/${nAssetID}/${WOObj.nServiceLocationID}`}>
            <button className="button" type="button" onClick={() => {}}>
              Upgrade ONT
            </button>
          </Link>
        </>
      ) : null}
      <div className={styles.displayMessage} id="msgDivAsset" />
    </div>
  );
}

export default Asset;
