let backendApi = window.location.origin.includes('dev.portal') ? window.location.origin : 'https://portal.socket.net';
if (window.location.origin.includes('localhost')) {
  backendApi = 'https://rkiew.dev.portal.socket.net';
}
export const BACKEND_API = backendApi;

export const ARCGIS_ENDPOINT_URL = 'https://gis.socket.net';

export const REPORT_NAMES = {
  ONE_PLUS_BILLING: '1+ Billing',
  EIGHT_HUNDRED_BILLING: '800 Billing',
  BUSY: 'Busy Report',
  CALL_VOLUME: 'Call Volume',
  CALL_VOLUME_NO_FILTER: 'Call Volume - no filter',
  CONCURRENT_CALLS: 'Concurrent Calls',
  FULL_MONTH: 'Full Month Report',
  OUTBOUND_CALLS: 'Outbound Calls',
  SUMMARY: 'Summary',
};

// the vcCustomerIds that we don't do a full log in due to the account size
export const LIGHT_LIGIN_IDS = [
  '0991-6700-3359', // System Services
];

export const LIGHT_LIGIN_USERNAMES = [
  'pdasal@health-systems-inc.com', // System Service
  'dunstedter@socket.net', // System Service
  'SysServAudit@socket.net', // System Service
  'mthomure@health-system-inc.com', // System Service
];

export const rezBizLinks = [
  { url: '/accountInfo', title: 'Account Info' },
  { url: '/services', title: 'Services' },
  { url: '/billingCenter', title: 'Billing Center' },
];

export const contractorLinks = [
  { url: '/contractorHome', title: 'Home' },
  { url: '/workOrders', title: 'Work Orders' },
  { url: '/scanAsset', title: 'Scan Asset' },
  { url: '/calendar', title: 'Calendar' },
  { url: '/maps', title: 'Maps' },
  { url: '/restorationTickets', title: 'Restoration Tickets' },
];

export const installerLinks = [
  { url: '/search', title: 'Search WO' },
  { url: '/savedWorkOrders', title: 'Saved WO' },
  { url: '/scanAsset', title: 'Scan an Asset' },
  { url: '/SavedAssets', title: 'Saved Assets' },
];

export const ACH_PAYMENT_ENDPOINT = 'https://central.electpay.net/onlinepayments/receivable/pay';
