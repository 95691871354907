import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import debounce from 'lodash.debounce';

import {
  getUpgradeEquipmentData,
  completeUpgrade,
  showPortsSlotsInfo,
  getAssetsTiedToUser,
} from '../../../../../utils';
import Loading from '../../../../shared/Loading';

import styles from './styles.module.scss';

function UpgradeONT() {
  const { currentUser } = useSelector((state) => state.session);
  const sessionFromReact = JSON.stringify(currentUser);
  const [parsed, setParsed] = useState({});
  // technically it should be called filtered instead of sorted, but the pattern is same as other 'sorted' so use the variable name for reference
  const [sorted, setSorted] = useState([]);
  const [assetsTiedToSelf, setAssetsTiedToSelf] = useState([]);
  const [equipmentToSwap, setEquipmentToSwap] = useState('');
  const [tempId, setTempId] = useState('');
  const [moveItemTo, setMoveItemTo] = useState('');
  const [loading, setLoading] = useState(false);
  const [filterInput, setFilterInput] = useState('');
  const [portsSlots, setPortsSlots] = useState([]);
  const [showEquipments, setShowEquipments] = useState(false);
  const [checkedPorts, setCheckedPorts] = useState({});
  const { ONTId } = useParams();

  const changeHandler = (e) => {
    setFilterInput(e.target.value.toLowerCase());
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 1000),
    [],
  );

  const idMediator = (e) => {
    setTempId(e.target.value.trim());
    debouncedChangeHandler(e);
  };

  const renderHeader = () => {
    const { asset } = parsed;
    if (!asset) {
      return null;
    }
    const { vcAssetID } = asset;
    return (
      <div>
        <h1 className={styles.assetInfoLabel}>Asset Tracking (Equipment Swap)</h1>
        <h3 className={styles.assetInfoLabel}>{`Asset # ${vcAssetID}`}</h3>
      </div>
    );
  };

  const renderEquipmentDefinition = (equipmentDefinition = parsed.equipment_definition, showSelect = false) => {
    if (!equipmentDefinition) {
      return null;
    }
    const {
      nCount,
      vcAssetID,
      vcDescription,
      vcEquipmentMaker,
      vcModel,
    } = equipmentDefinition;
    return (
      <div className={`${styles.assetInfoGroup}`} key={`${nCount}-${vcAssetID}-${showSelect}-equipmentDefinition-div`}>
        {showSelect ? (
          <input className={styles.checkButton} id={`equipmentToSwapRadio-${nCount}`} value={equipmentToSwap} type="radio" onChange={() => setEquipmentToSwap(nCount)} name="equipmentToSwap" />
        ) : null}
        <label htmlFor={`equipmentToSwapRadio-${nCount}`}>
          <div className={styles.assetInfo}>
            <span className={styles.assetInfoLabel}>Asset#</span>
            <span className="assetInfo">{showSelect ? vcAssetID : parsed.asset.vcAssetID}</span>
          </div>
          <div className={styles.assetInfo}>
            <span className={styles.assetInfoLabel}>Equipment Type</span>
            <span className="assetInfo">{vcDescription}</span>
          </div>
          <div className={styles.assetInfo}>
            <span className={styles.assetInfoLabel}>Equipment Make</span>
            <span className="assetInfo">{vcEquipmentMaker}</span>
          </div>
          <div className={styles.assetInfo}>
            <span className={styles.assetInfoLabel}>Equipment Model</span>
            <span className="assetInfo">{vcModel}</span>
          </div>
          <div className={styles.assetInfoBorder} />
        </label>
      </div>
    );
  };

  const renderAvailableEquipments = () => {
    if (!sorted.length) {
      return <div>No available equipments</div>;
    }
    if (!showEquipments && sorted.length > 10) {
      return <div>Hiding the full list, toggle to see the result</div>;
    }
    return sorted.map((eq) => {
      const { showOption } = eq;
      if (!showOption) {
        return null;
      }
      return renderEquipmentDefinition(eq, true);
    });
  };

  const renderNewServiceItems = () => {
    const serviceLocationsItems = parsed.service_locations;
    if (!serviceLocationsItems || !serviceLocationsItems.length) {
      return null;
    }
    const options = serviceLocationsItems.map((item) => {
      const {
        nCount,
        MasterServiceItemID = '',
        ServiceItemDesc = '',
        vcAddress = '',
        vcAddress2 = '',
        vcCity = '',
        vcState = '',
        vcZip = '',
        PackageName = '',
        DateEntered = '',
        nOrderID = '',
        LinePrice = '',
      } = item;
      const address2Str = vcAddress2 ? `${vcAddress2}, ` : '';
      const packageStr = PackageName ? `${PackageName}, ` : '';
      return (
        <option key={`${nCount}-option`} value={MasterServiceItemID}>
          {`${ServiceItemDesc}: ${vcAddress}, ${address2Str} ${vcCity}, ${vcState} ${vcZip} ${packageStr} (${DateEntered}) | Sales Order # ${nOrderID} | $${LinePrice}`}
        </option>
      );
    });
    return (
      <div>
        <h3>Move Service Item:</h3>
        <select value={moveItemTo} onChange={(e) => setMoveItemTo(e.target.value)}>
          {options}
        </select>
      </div>
    );
  };

  const handleShowPortsSlotsInfo = () => {
    const formData = {};
    formData.OrigEquip = parsed.asset?.nCount;
    formData.NewAsset = equipmentToSwap;
    showPortsSlotsInfo(sessionFromReact, formData)
      .then((response) => {
        setPortsSlots(JSON.parse(response));
      });
  };

  const handleCompleteUpgrade = () => {
    const formData = {};
    formData.OrigEquip = parsed.asset?.nCount;
    formData.NewAsset = equipmentToSwap;
    formData.TotalPorts = portsSlots.oldDefP.length;
    // ONT devices should only have one port, the key is just the index of the port
    formData.Ports = checkedPorts;

    // eslint-disable-next-line
    completeUpgrade(sessionFromReact, formData)
      .then((response) => {
        if (JSON.parse(response).nAssetID === equipmentToSwap) {
          Swal.fire({
            icon: 'success',
            text: 'Asset swapped successfully.',
          });
        } else {
          throw response;
        }
      })
      .catch((e) => {
        Swal.fire({
          icon: 'warning',
          text: `Oops, something went wrong. Please contact the office and report to IT. ${e}`,
        });
      });
  };

  const handlePortsSlots = (setter, idx, id) => {
    const newMap = {};
    // we should only have one port defined for WAN labelled PON
    newMap[idx] = id;
    setter(newMap);
  };

  const renderPorts = () => {
    if (!portsSlots.oldDefP && !portsSlots.oldDefS) {
      return null;
    }
    const portsDiv = portsSlots.oldDefP.map((p) => {
      const {
        vcPortLabel,
        nPortID,
        SocketRecordID,
        vcSocketCircuitID,
        newDefP,
      } = p;
      // The naming is bad, the pt.nPortID is actually nPortIndex
      const portsRadioButtons = newDefP.map((pt) => (
        <label key={`${pt.nPortID}-${pt.vcPortLabel}-label`}>
          {pt.vcPortLabel}
          <input type="radio" checked={checkedPorts[pt.nPortID] === nPortID} onChange={() => handlePortsSlots(setCheckedPorts, pt.nPortID, nPortID)} id={`nPortID-${pt.nPortID}`} />
        </label>
      ));
      return (
        <div key={`${SocketRecordID}-${vcPortLabel}-div`} className={styles.wrapper}>
          <div className={styles.equipmentBlock}>
            <h3>Old Equipment</h3>
            <span>{vcPortLabel}</span>
            <span><a target="_blank" href={`http://inside.sockettelecom.com/circuits/SocketCircuits/view.php?CircuitID=${SocketRecordID}`} rel="noreferrer">{vcSocketCircuitID}</a></span>
          </div>
          <div className={styles.equipmentBlock}>
            <h3>New Equipment</h3>
            {portsRadioButtons}
          </div>
        </div>
      );
    });

    return (
      <div className={styles.portsSlots}>
        <h3>Define Ports</h3>
        {portsDiv}
      </div>
    );
  };

  const renderTiedToSelfAssets = () => {
    if (assetsTiedToSelf.length === 0) {
      return <div>No asset assigned to you found</div>;
    }
    const assets = assetsTiedToSelf.map((a) => renderEquipmentDefinition(a, true));
    return (
      <div>
        <h3>Assets assigned to you:</h3>
        {assets}
      </div>
    );
  };

  useEffect(() => {
    getAssetsTiedToUser(sessionFromReact, currentUser.nNOCLocationID)
      .then((response) => setAssetsTiedToSelf(JSON.parse(response)))
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    getUpgradeEquipmentData(sessionFromReact, ONTId)
      .then((response) => {
        if (!isMounted) {
          return;
        }
        setParsed(JSON.parse(response));
      })
      .then(() => { setLoading(false); });
    return () => { isMounted = false; };
  }, [sessionFromReact, ONTId]);

  useEffect(() => {
    if (!equipmentToSwap) {
      return;
    }
    handleShowPortsSlotsInfo();
  }, [equipmentToSwap]);

  useEffect(() => {
    const availableEquipments = parsed.available_equipment;
    if (!availableEquipments) {
      return;
    }

    setSorted((availableEquipments)
      .filter((eq) => Object.keys(eq).some((key) => eq[key].toString().toLowerCase().includes(filterInput))));
  }, [parsed, filterInput]);

  return (
    <div>
      {loading ? <Loading /> : null}
      <div className="center">
        {renderHeader()}
      </div>
      {renderTiedToSelfAssets()}
      <label>
        Search for an Asset:
        <input
          type="text"
          id="searchBar"
          onChange={idMediator}
          value={tempId}
          placeholder="ex. KETXXXXX"
        />
      </label>
      {renderEquipmentDefinition()}
      <button type="button" onClick={() => setShowEquipments(!showEquipments)}>
        {`${showEquipments ? 'Hide' : 'Show'} all equipments`}
      </button>
      <div className={styles.assetList}>
        {renderAvailableEquipments()}
        {renderNewServiceItems()}
      </div>
      {renderPorts()}
      <div className="center">
        <button className="button" type="button" onClick={handleCompleteUpgrade}>Upgrade (replace with) selected asset</button>
      </div>
    </div>
  );
}

export default UpgradeONT;
