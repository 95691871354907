import React, { useEffect } from 'react';

import styles from './styles.module.scss';

function Notes({ txtNotes = 'No notes available for this work order' }) {
  useEffect(() => {
    document.getElementById('Notes').innerHTML = txtNotes;
  }, []);

  return <div id="Notes" className={styles.Notes} />;
}

export default Notes;
