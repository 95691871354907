import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  updateCheckLists,
  getInstallWorkOrderDetails,
  logout,
  handleMessage,
} from '../../../../utils';
import { disableInputs } from '../../utils';
import { preSubmitChecker } from '../functions';
import Loading from '../../Loading';

const defaultFormData = {
  installCategories: 'None specified',
  lastUpdated: '',
  dateCompleted: '',
  resolved: false,
  contactedLCON: false,
  contactedVendor: false,
  yardSignPlaced: false,
  additionalDispatchRequired: false,
  noDispatchRequired: false,
  arrivalDate: '',
  arrivalTime: '00:00',
  completedDate: '',
  completedTime: '00:00',
  equipmentInstalled: '',
  additionalMaterials: '',
  speedTestURL: '',
  testsPerformed: '',
  additionalNotes: '',
  WOComplete: false,
};

function ColoInstall({ WorkOrderID, closeEditModal = () => {}, type }) {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.session);
  const sessionFromReact = JSON.stringify(currentUser);
  const [formData, setFormData] = useState(defaultFormData);
  const [loading, setLoading] = useState(false);

  const {
    installCategories,
    lastUpdated,
    dateCompleted,
    resolved,
    contactedLCON,
    contactedVendor,
    yardSignPlaced,
    additionalDispatchRequired,
    noDispatchRequired,
    arrivalDate,
    arrivalTime,
    completedDate,
    completedTime,
    equipmentInstalled,
    additionalMaterials,
    speedTestURL,
    testsPerformed,
    additionalNotes,
    WOComplete,
  } = formData;

  const handleSubmit = () => {
    handleMessage('', 'Modal');
    setLoading(true);
    const payload = {};
    payload.WorkOrderID = WorkOrderID;
    // the payload are accessed in
    // provapp1 functions/api/workorders/workorder.php -> editWorkOrder($EditParams)
    if (resolved) {
      payload.nResolved = 1;
    }
    if (contactedLCON) {
      payload.nInitialContact = 1;
    }
    if (contactedVendor) {
      payload.nVendorContact = 1;
    }
    if (additionalDispatchRequired) {
      payload.nAdditionalDispatch = 1;
    }
    if (noDispatchRequired) {
      payload.nNoDispatch = 1;
    }
    payload.ArrivalOnsite = arrivalDate;
    payload.ArrivalTime = arrivalTime;
    payload.CompletedDate = completedDate;
    payload.CompletedTime = completedTime;
    payload.txtEquipmentInstalled = equipmentInstalled;
    payload.txtAdditionalMaterials = additionalMaterials;
    if (type === 'consumer' && yardSignPlaced) {
      payload.YardSignPlaced = 1;
    }
    payload.SpeedTestURL = speedTestURL;
    payload.txtTestsPerformed = testsPerformed;
    payload.AdditionalNotes = additionalNotes;
    payload.WOComplete = WOComplete ? 'on' : 'off';
    payload.UtilityID = 15;
    updateCheckLists(sessionFromReact, payload)
      .then((response) => {
        if (response && response.toLowerCase().includes('error')) {
          handleMessage(response, 'Modal');
        } else {
          handleMessage('Updated successfully', 'Modal');
          closeEditModal();
        }
      })
      .catch((error) => {
        // session related error
        if (error.status === 401) {
          dispatch(logout(error));
        }
      })
      .then(() => { setLoading(false); });
  };

  useEffect(() => {
    let isMounted = true;
    getInstallWorkOrderDetails(sessionFromReact, WorkOrderID, type)
      .then((response) => {
        if (!response || !isMounted) {
          return;
        }
        const parsed = JSON.parse(response);
        const {
          nResolved,
          vcInstallCategories,
          dtLastUpdated,
          dtDateCompleted,
          nInitialContact,
          nVendorContact,
          nYardSignPlaced,
          nAdditionalDispatch,
          nNoDispatch,
          tsArrivalOnsite,
          tsWorkComplete,
          txtEquipment,
          txtAdditionalMaterials,
          txtSpeedTestURL,
          txtTestsPerformed,
          txtNotes,
          vcStatus,
        } = parsed;

        const [date, time] = (tsArrivalOnsite || '').split(' ');
        const [completeDate, completeTime] = (tsWorkComplete || '').split(' ');
        const completed = vcStatus === 'Complete';
        const newFormData = {
          ...formData,
          installCategories: vcInstallCategories || 'None specified',
          lastUpdated: dtLastUpdated || '',
          dateCompleted: dtDateCompleted || '',
          resolved: nResolved === '1',
          contactedLCON: nInitialContact === '1',
          contactedVendor: nVendorContact === '1',
          yardSignPlaced: nYardSignPlaced === '1',
          additionalDispatchRequired: nAdditionalDispatch === '1',
          noDispatchRequired: nNoDispatch === '1',
          arrivalDate: date || '',
          arrivalTime: time || '00:00',
          completedDate: completeDate || '',
          completedTime: completeTime || '00:00',
          equipmentInstalled: txtEquipment || '',
          additionalMaterials: txtAdditionalMaterials || '',
          speedTestURL: txtSpeedTestURL || '',
          testsPerformed: txtTestsPerformed || '',
          additionalNotes: txtNotes || '',
          WOComplete: completed,
        };
        disableInputs(completed);
        setFormData(newFormData);
      })
      .catch((error) => {
        console.log(error);
        // session related error
        if (error.status === 401) {
          dispatch(logout(error));
        }
      });
    return () => { isMounted = false; };
  }, [WorkOrderID]);

  let customerTypeStr = '';
  if (type === 'business') {
    customerTypeStr = 'Business ColoInstall';
  } else if (type === 'consumer') {
    customerTypeStr = 'Residential ColoInstall';
  } else if (type === 'dispatch') {
    customerTypeStr = 'Dispatch';
  }

  return (
    <table className="editWOForm darkergray">
      <tbody>
        <tr className="dataBlock">
          <td colSpan={2} className="title"><h3 className="centerContent">{`Onsite Checklist - ${customerTypeStr}`}</h3></td>
        </tr>
        {type === 'business' ? (
          <tr className="header">
            <td className="centerContent">
              <span>Install Cetegories:</span>
              <span>{installCategories}</span>
            </td>
            <td className="centerContent">
              <span>Last Modified:</span>
              <span>{lastUpdated}</span>
            </td>
            <td className="centerContent">
              <span>Date Completed:</span>
              <span>{dateCompleted}</span>
            </td>
          </tr>
        ) : null}
        <tr className="checkBoxes">
          {type === 'dispatch' ? (
            <td className="lineCheck">
              <label htmlFor="resolved">
                Problem Resolved?:
              </label>
              <input type="checkbox" id="resolved" checked={resolved} onChange={() => setFormData({ ...formData, resolved: !resolved })} />
            </td>
          ) : null}
          <td className="lineCheck">
            <label htmlFor="initialContact">
              Initial Contact Made with LCON?:
            </label>
            <input type="checkbox" id="initialContact" checked={contactedLCON} onChange={() => setFormData({ ...formData, contactedLCON: !contactedLCON })} />
          </td>
          <td className="lineCheck">
            <label htmlFor="contactWithVendor">
              Contact Made with IT/Data Vendor:
            </label>
            <input type="checkbox" id="contactWithVendor" checked={contactedVendor} onChange={() => setFormData({ ...formData, contactedVendor: !contactedVendor })} />
          </td>
          {type === 'consumer' ? (
            <td className="lineCheck">
              <label htmlFor="yardSign">
                Yard Sign Placed?:
              </label>
              <input type="checkbox" id="yardSign" checked={yardSignPlaced} onChange={() => setFormData({ ...formData, yardSignPlaced: !yardSignPlaced })} />
            </td>
          ) : null}
          <td className="lineCheck">
            <label htmlFor="additionalDispatch">
              Additional Dispatch Required:
            </label>
            <input type="checkbox" id="additionalDispatch" checked={additionalDispatchRequired} onChange={() => setFormData({ ...formData, additionalDispatchRequired: !additionalDispatchRequired })} />
          </td>
          <td className="lineCheck">
            <label htmlFor="noDispatch">
              No Dispatch Required:
              <br />
              (please specify why in notes)
            </label>
            <input type="checkbox" id="noDispatch" checked={noDispatchRequired} onChange={() => setFormData({ ...formData, noDispatchRequired: !noDispatchRequired })} />
          </td>
        </tr>
        <tr className="dataBlock">
          <td className="centerContent"><p>Date/Time of Arrival Onsite:</p></td>
          <td className="centerContent">
            <input className="width50Percent" type="date" value={arrivalDate} onChange={(e) => setFormData({ ...formData, arrivalDate: e.target.value })} placeholder="yyyy-mm-dd" />
            <input className="width50Percent" type="time" value={arrivalTime} onChange={(e) => setFormData({ ...formData, arrivalTime: e.target.value })} />
          </td>
        </tr>
        <tr className="dataBlock">
          <td className="centerContent"><p>Date/Time of Work Completed</p></td>
          <td className="centerContent">
            <input className="width50Percent" type="date" value={completedDate} onChange={(e) => setFormData({ ...formData, completedDate: e.target.value })} placeholder="yyyy-mm-dd" />
            <input className="width50Percent" type="time" value={completedTime} onChange={(e) => setFormData({ ...formData, completedTime: e.target.value })} />
          </td>
        </tr>
        <tr className="dataBlock">
          <td className="centerContent">Equipment Installed:</td>
          <td className="centerContent">
            <textarea
              className="textBlockSize"
              value={equipmentInstalled}
              onChange={(e) => setFormData({ ...formData, equipmentInstalled: e.target.value })}
            />
          </td>
        </tr>
        <tr className="dataBlock">
          <td className="centerContent">Additional Meterials:</td>
          <td className="centerContent">
            <textarea
              className="textBlockSize"
              value={additionalMaterials}
              onChange={(e) => setFormData({ ...formData, additionalMaterials: e.target.value })}
            />
          </td>
        </tr>
        <tr className="dataBlock">
          <td className="centerContent paddingTop10"><p>Speedtest.net Result Image URL:</p></td>
          <td className="centerContent">
            <input
              className="speedTestBlock"
              value={speedTestURL}
              onChange={(e) => setFormData({ ...formData, speedTestURL: e.target.value })}
              placeholder="http://www.speedtest.net/result/4173155906.png"
            />
          </td>
        </tr>
        <tr className="dataBlock">
          <td className="centerContent"><p>Test Performed:</p></td>
          <td className="centerContent">
            <textarea
              className="textBlockSize"
              value={testsPerformed}
              onChange={(e) => setFormData({ ...formData, testsPerformed: e.target.value })}
            />
          </td>
        </tr>
        <tr className="dataBlock">
          <td className="centerContent"><p>Additional Notes:</p></td>
          <td className="centerContent">
            <textarea
              className="textBlockSize"
              value={additionalNotes}
              onChange={(e) => setFormData({ ...formData, additionalNotes: e.target.value })}
            />
          </td>
        </tr>
        <tr className="checkBoxes">
          <td className="lineCheck">
            <label htmlFor="complete">Complete Work Order?</label>
            <input id="complete" type="checkbox" checked={WOComplete} onChange={() => setFormData({ ...formData, WOComplete: !WOComplete })} />
          </td>
        </tr>
        <tr className="dataBlock">
          <td className="update-div centerContent" colSpan={2}>
            {loading ? <Loading /> : <button type="button" onClick={() => preSubmitChecker(WOComplete, handleSubmit)}>Update Checklist</button>}
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <div id="msgDivModal" />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default ColoInstall;
