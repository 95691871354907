import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
  handleAddressStr, saveLocalStorage,
} from '../../../utils';

import SearchContext from './SearchContext';
import styles from './styles.module.scss';

function SearchResult({ resultProp, sort = ['', 1] }) {
  const { pathname } = useLocation();
  const { results } = useContext(SearchContext) || {};
  // when going from the Saved WO tab, the setResults context isn't available
  // create a local state instead
  const [resultState, setResultState] = useState(resultProp || results);
  const [sortBy, order] = sort;

  useEffect(() => {
    setResultState(resultProp);
  }, [resultProp]);

  if (!resultState || !resultState.length) {
    return <h1>No results found</h1>;
  }

  const deleteSavedOrder = (e, workID) => {
    e.preventDefault();
    const prevSavedWorkOrders = localStorage.getItem('savedWorkOrders');
    const newSavedWorkOrders = prevSavedWorkOrders ? JSON.parse(prevSavedWorkOrders) : [];
    newSavedWorkOrders.splice(newSavedWorkOrders.findIndex((ele) => ele.nCount === workID), 1);
    saveLocalStorage('savedWorkOrders', newSavedWorkOrders);
    setResultState(newSavedWorkOrders);
  };
  const isSavedWorkOrdersPage = pathname === '/savedWorkOrders';
  if (!resultState || !resultState.length) {
    return (
      <div>
        No Work Orders Found
      </div>
    );
  }
  const lis = resultState.sort((a, b) => (a[sortBy]?.localeCompare(b[sortBy]) || -1) * order).map((ele) => {
    const {
      nCount, // workOrderID
      vcWorkOrderType,
      vcCustomerName,
      vcNextScheduledName = '', // eventTitle
      vcNextScheduledTime = '', // eventTime
      vcColoArea,
      dtDueDate,
    } = ele;
    return (
      <li key={`${nCount}-li`} className={`${styles.appList} ${styles.borderBottom} ${styles.WOSearchResult}`}>
        <Link to={`/search/${nCount}`}>
          <div>{`Event Title: ${vcNextScheduledName}`}</div>
          <div>{`Event Time: ${vcNextScheduledTime}`}</div>
          <div className={styles.space}>
            <span>{`Due Date: ${dtDueDate}`}</span>
          </div>
          <div className={styles.space}>
            <div className={styles.fontsize}>
              {vcCustomerName}
            </div>
            {isSavedWorkOrdersPage ? (
              <button className={styles.deleteButton} type="button" onClick={(e) => deleteSavedOrder(e, nCount)}>Delete</button>
            ) : null}
          </div>
          <div>{handleAddressStr(ele)}</div>
          <div>{`Colo Area: ${vcColoArea}`}</div>
          <div className={styles.space}>
            <span>{nCount}</span>
            <span>{vcWorkOrderType}</span>
          </div>
        </Link>
      </li>
    );
  });

  return (
    <div className={styles.SearchResult}>
      Work Orders:
      <ul>
        {lis}
      </ul>
    </div>
  );
}

export default SearchResult;
