import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  getAttachmentList,
  getAttachment,
  logout,
  BACKEND_API,
} from '../../../../../utils';
import Loading from '../../../../shared/Loading';

import styles from '../styles.module.scss';

function Attachments() {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.session);
  const sessionFromReact = JSON.stringify(currentUser);
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(true);
  const { WOId } = useParams();

  const handleDownloadAttachment = (nCount, vcFileName) => {
    setLoading(true);
    getAttachment(sessionFromReact, WOId, nCount, vcFileName)
      .then((response) => {
        window.open(`${BACKEND_API}${response}`);
      })
      .catch((error) => {
        // session related error
        if (error.status === 401) {
          dispatch(logout(error));
        }
      })
      .then(() => { setLoading(false); });
  };

  const renderAttachments = () => {
    if (!attachments.length) {
      return (
        <h1>No attachments available</h1>
      );
    }
    if (!attachments || !attachments.length) {
      return (
        <div>
          No Attatchments Found
        </div>
      );
    }
    const atthes = attachments.map((at) => {
      const { nCount, vcFileName } = at;
      return (
        <div className={styles.buttonPaddingTop} key={`${nCount}-${vcFileName}-div`}>
          <button type="button" className="linkText" onClick={() => handleDownloadAttachment(nCount, vcFileName)}>
            {`File name: ${vcFileName}`}
          </button>
        </div>
      );
    });
    return (
      <div>
        <h1 className={styles.title}>Available attachments:</h1>
        {atthes}
      </div>
    );
  };

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    getAttachmentList(sessionFromReact, WOId)
      .then((response) => {
        if (!isMounted) {
          return;
        }
        const parsed = JSON.parse(response);
        if (parsed) {
          setAttachments(parsed);
        }
      })
      .catch((error) => {
        // session related error
        if (error.status === 401) {
          dispatch(logout(error));
        }
      })
      .then(() => { setLoading(false); });
    return () => { isMounted = false; };
  }, []);

  return (
    <div>
      {loading ? <Loading /> : renderAttachments()}
    </div>
  );
}

export default Attachments;
