import React, { useState, useEffect } from 'react';
import { initializeSavedAssets, saveLocalStorage } from '../../../utils';
import Asset from '../shared/Assets/Asset';
import AssetActions from '../shared/Assets/AssetActions';

import styles from './styles.module.scss';

function SavedAssets() {
  const [showDetails, setShowDetails] = useState([]);
  const [showActions, setShowActions] = useState([]);
  const [savedAssetsObj, setSavedAssetsObj] = useState({});

  const toggleDeatils = (idx) => {
    const newDetails = [...showDetails];
    newDetails[idx] = !newDetails[idx];
    setShowDetails(newDetails);
  };

  const toggleActions = (idx) => {
    const newActions = [...showActions];
    newActions[idx] = !newActions[idx];
    setShowActions(newActions);
  };

  const deleteAsset = (e, vcAssetId) => {
    e.preventDefault();
    const newActionObj = initializeSavedAssets();
    delete newActionObj[vcAssetId];
    saveLocalStorage('savedAssets', newActionObj);
    setSavedAssetsObj(newActionObj);
  };

  const renderSavedAssets = () => {
    if (!Object.keys(savedAssetsObj).length) {
      return (
        <>
          <h1>No saved Assets</h1>
          <p>If you tried to load the asset actions but failed due to bad internet connection, the assets will appear here for you to retry</p>
        </>
      );
    }
    const lis = Object.keys(savedAssetsObj).map((vcAssetId, idx) => {
      const { WOId, nAssetId } = savedAssetsObj[vcAssetId];
      const WOStr = WOId ? `WO# ${WOId}` : '';
      return (
        <li key={`${vcAssetId}-li`}>
          <div className="paddingBottom10">
            <div className="bold">
              {`${vcAssetId} ${WOStr}`}
            </div>
            <button className="button" type="button" onClick={() => toggleDeatils(idx)}>
              {showDetails[idx] ? 'Hide Details' : 'Show Details'}
            </button>
            {WOId ? (
              <button className="button" type="button" onClick={() => toggleActions(idx)}>
                {showActions[idx] ? 'Hide Actions' : 'Show Actions'}
              </button>
            ) : null}
            <button className="button" type="button" onClick={(e) => deleteAsset(e, vcAssetId)}>Delete</button>
            {showDetails[idx] ? (
              <Asset vcAssetIdProp={vcAssetId} nAssetIdProp={nAssetId} WOIdProp={WOId} />
            ) : null}
            {showActions[idx] ? (
              <AssetActions vcAssetIdProp={vcAssetId} nAssetIdProp={nAssetId} WOIdProp={WOId} />
            ) : null}
          </div>
        </li>
      );
    });
    return (
      <ul>
        {lis}
      </ul>
    );
  };

  useEffect(() => {
    const savedAssets = initializeSavedAssets();
    if (Object.keys(savedAssets).length > 0) {
      setSavedAssetsObj(savedAssets);
      const showDetailsArr = [];
      const showActionsArr = [];
      for (let i = 0; i < Object.keys(savedAssets).length; i += 1) {
        showDetailsArr.push(false);
        showActionsArr.push(false);
      }
      setShowDetails(showDetailsArr);
      setShowActions(showActionsArr);
    }
  }, []);

  return (
    <div>
      <div className={styles.flexitup}>
        <div className="bold">
          Saved Assets
        </div>
      </div>
      <br />
      <div>
        {renderSavedAssets()}
      </div>
    </div>
  );
}

export default SavedAssets;
