import React, { useEffect, useContext, useState } from 'react';
import { useSelector } from 'react-redux';

import { viewPDF, BACKEND_API, fireError } from '../../../../utils';
import WorkOrderContext from './WorkOrderContext';

// eslint-disable-next-line
import styles from './styles.module.scss';

function ViewPDF() {
  const { currentUser } = useSelector((state) => state.session);
  const sessionFromReact = JSON.stringify(currentUser);
  const { WOObj } = useContext(WorkOrderContext);
  const [pdfPath, setPdfPath] = useState('');

  const handleDownloadClick = () => { window.open(pdfPath); };

  useEffect(() => {
    viewPDF(sessionFromReact, WOObj.WorkOrderID)
      .then((response) => {
        setPdfPath(`${BACKEND_API}${response}`);
      })
      .catch((error) => {
        console.log(error);
        fireError(error.statusText);
      });
  }, []);

  return (
    <object data={pdfPath} type="application/pdf" className={styles.viewPDF}>
      <p>
        <button type="button" onClick={handleDownloadClick}>Download</button>
      </p>
    </object>
  );
}

export default ViewPDF;
