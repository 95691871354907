// parentClass is the element class that has width 100%
export const scroller = (parentClass) => {
  const slider = document.querySelector(parentClass);
  if (!slider) {
    console.log(`slider not found. parentClass: ${parentClass}`);
    return;
  }
  slider.classList.add('grab');
  let isDown = false;
  let startX;
  let scrollLeft;
  let velX = 0;
  let momentumID;
  const momentumLoop = () => {
    slider.scrollLeft += velX;
    velX *= 0.95;
    if (Math.abs(velX) > 0.5) {
      momentumID = requestAnimationFrame(momentumLoop);
    }
  };

  const cancelMomentumTracking = () => {
    cancelAnimationFrame(momentumID);
  };

  const beginMomentumTracking = () => {
    cancelMomentumTracking();
    momentumID = requestAnimationFrame(momentumLoop);
  };

  slider.addEventListener('mousedown', (e) => {
    isDown = true;
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
    cancelMomentumTracking();
  });

  slider.addEventListener('mouseleave', () => {
    isDown = false;
  });

  slider.addEventListener('mouseup', () => {
    isDown = false;
    beginMomentumTracking();
  });

  slider.addEventListener('mousemove', (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - startX) * 3; // scroll-fast
    const prevScrollLeft = slider.scrollLeft;
    slider.scrollLeft = scrollLeft - walk;
    velX = slider.scrollLeft - prevScrollLeft;
  });

  slider.addEventListener('wheel', () => {
    cancelMomentumTracking();
  });
};
