import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { clearPusherMsg, hidePusher } from '../../../actions/pusherActions';
import styles from './styles.module.scss';

function Pusher() {
  const dispatch = useDispatch();
  const { pusher } = useSelector((state) => state);
  const { msg, show } = pusher;

  const handlePusherClick = () => {
    dispatch(clearPusherMsg());
    dispatch(hidePusher());
  };

  if (!show) {
    return null;
  }
  return (
    <div
      className={styles.Pusher}
      role="button"
      tabIndex={0}
      onKeyDown={() => {}}
      onClick={handlePusherClick}
    >
      <div className={styles.popup}>
        {msg}
      </div>
    </div>
  );
}

export default Pusher;
