export const RECEIVE_CURRENT_USER = 'RECEIVE_CURRENT_USER';
export const RECEIVE_USER_LOGOUT = 'RECEIVE_USER_LOGOUT';
export const RECEIVE_SESSION_ERRORS = 'RECEIVE_SESSION_ERRORS';
export const CLEAR_SESSION_ERRORS = 'CLEAR_SESSION_ERRORS';
export const RECEIVE_ACTIVE_SERVICE_LOCATION = 'RECEIVE_ACTIVE_SERVICE_LOCATION';
export const RECEIVE_ACTIVE_MAILING_LOCATION = 'RECEIVE_ACTIVE_MAILING_LOCATION';

export const receiveCurrentUser = (currentUser) => ({
  type: RECEIVE_CURRENT_USER,
  currentUser,
});

export const logoutUser = () => ({
  type: RECEIVE_USER_LOGOUT,
});

export const recieveSessionErrors = (errors = '') => ({
  type: RECEIVE_SESSION_ERRORS,
  errors: errors.replaceAll(/\r|\t|\n/gm, ''),
});

export const clearSessionErrors = () => ({
  type: CLEAR_SESSION_ERRORS,
});

export const receiveActivetServiceLocation = (nActiveServiceLocation) => ({
  type: RECEIVE_ACTIVE_SERVICE_LOCATION,
  nActiveServiceLocation,
});

export const receiveActivetMailingLocation = (nActiveMailingLocation) => ({
  type: RECEIVE_ACTIVE_MAILING_LOCATION,
  nActiveMailingLocation,
});

export const saveSession = (sessionFromReact) => {
  localStorage.setItem('token', sessionFromReact);
};
