import { cacheResponseHelper } from './helpers';
import { BACKEND_API } from './constants';

const postToInstallerBack = (data) => cacheResponseHelper(data, `${BACKEND_API}/backend/functions/installerBack/installerBack.php`);

export const searchWorkOrders = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'searchWorkOrders',
    ...formData,
  };
  return postToInstallerBack(data);
};

export const getWorkOrderObject = (sessionFromReact, WorkOrderID) => {
  const data = {
    sessionFromReact,
    action: 'getWorkOrderObject',
    WorkOrderID,
  };
  return postToInstallerBack(data);
};

export const getServiceLocationAssets = (sessionFromReact, nServiceLocationID) => {
  const data = {
    sessionFromReact,
    action: 'getServiceLocationAssets',
    nServiceLocationID,
  };
  return postToInstallerBack(data);
};

export const getAsset = (sessionFromReact, AssetID) => {
  const data = {
    sessionFromReact,
    action: 'getAsset',
    AssetID,
  };
  return postToInstallerBack(data);
};

export const getWorkOrdersAvailableActions = (sessionFromReact, WorkOrderID, AssetID) => {
  const data = {
    sessionFromReact,
    action: 'getWorkOrdersAvailableActions',
    WorkOrderID,
    AssetID,
  };
  return postToInstallerBack(data);
};

export const executeWorkOrdersAction = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'executeWorkOrdersAction',
    ...formData,
  };
  return postToInstallerBack(data);
};

export const getSalesOrderAction = (sessionFromReact, WorkOrderID, SalesOrderID) => {
  const data = {
    sessionFromReact,
    action: 'getSalesOrderAction',
    WorkOrderID,
    SalesOrderID,
  };
  return postToInstallerBack(data);
};

export const getInstallWorkOrderDetails = (sessionFromReact, nCount, type) => {
  const data = {
    sessionFromReact,
    action: 'getInstallWorkOrderDetails',
    nCount,
    type,
  };
  return postToInstallerBack(data);
};

export const getAttachmentList = (sessionFromReact, WorkOrderID) => {
  const data = {
    sessionFromReact,
    action: 'getAttachmentList',
    WorkOrderID,
  };
  return postToInstallerBack(data);
};

export const getAttachment = (sessionFromReact, WorkOrderID, nCount, fileName) => {
  const data = {
    sessionFromReact,
    action: 'getAttachment',
    WorkOrderID,
    nCount,
    fileName,
  };
  return postToInstallerBack(data);
};

export const getCircuitsFromSO = (sessionFromReact, ServiceLocationID) => {
  const data = {
    sessionFromReact,
    action: 'getCircuitsFromSO',
    ServiceLocationID,
  };
  return postToInstallerBack(data);
};

export const getCircuitStats = (sessionFromReact, vcSocketCircuitID) => {
  const data = {
    sessionFromReact,
    action: 'getCircuitStats',
    vcSocketCircuitID,
  };
  return postToInstallerBack(data);
};

export const getPhysicalPlantConfig = (sessionFromReact, nOrderID) => {
  const data = {
    sessionFromReact,
    action: 'getPhysicalPlantConfig',
    nOrderID,
  };
  return postToInstallerBack(data);
};

export const getAssetEditData = (sessionFromReact, nAssetID) => {
  const data = {
    sessionFromReact,
    action: 'getAssetEditData',
    nAssetID,
  };
  return postToInstallerBack(data);
};

export const getWorkOrdersActionResponse = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'getWorkOrdersActionResponse',
    ...formData,
  };
  return postToInstallerBack(data);
};

export const checkAssetTiedToUser = (sessionFromReact, nLocationID, nCount) => {
  const data = {
    sessionFromReact,
    action: 'checkAssetTiedToUser',
    nLocationID,
    nCount,
  };
  return postToInstallerBack(data);
};

export const getSwapEquipmentData = (sessionFromReact, nAssetID) => {
  const data = {
    sessionFromReact,
    action: 'getSwapEquipmentData',
    nAssetID,
  };
  return postToInstallerBack(data);
};

export const completeSwap = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'completeSwap',
    ...formData,
  };
  return postToInstallerBack(data);
};

export const getUpgradeEquipmentData = (sessionFromReact, nAssetID) => {
  const data = {
    sessionFromReact,
    action: 'getUpgradeEquipmentData',
    nAssetID,
  };
  return postToInstallerBack(data);
};

export const showPortsSlotsInfo = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'showPortsSlotsInfo',
    ...formData,
  };
  return postToInstallerBack(data);
};

export const completeUpgrade = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'completeUpgrade',
    ...formData,
  };
  return postToInstallerBack(data);
};

export const setTestTone = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'setTestTone',
    ...formData,
  };
  return postToInstallerBack(data);
};

export const getWorkorderTypes = (sessionFromReact) => {
  const data = {
    sessionFromReact,
    action: 'getWorkorderTypes',
  };
  return postToInstallerBack(data);
};

export const getAssetsTiedToUser = (sessionFromReact, nLocationID) => {
  const data = {
    sessionFromReact,
    action: 'getAssetsTiedToUser',
    nLocationID,
  };
  return postToInstallerBack(data);
};

export const getEstimatedDropLength = (sessionFromReact, nOrderID) => {
  const data = {
    sessionFromReact,
    action: 'getEstimatedDropLength',
    nOrderID,
  };
  return postToInstallerBack(data);
};
