import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getFiberConnectorizedInstallWorkOrderDetails,
  updateCheckLists,
  getSpliceCaseTypes,
  logout,
  handleMessage,
  getFiberDropTypesWorkOrders,
  getEstimatedDropLength,
} from '../../../../utils';
import { disableInputs } from '../../utils';
import { preSubmitChecker } from '../functions';
import AssemblyUnits from '../../EditWOForm/AssemblyUnits';
import Loading from '../../Loading';

const defaultFormData = {
  arrivalDate: '',
  arrivalTime: '00:00',
  completedDate: '',
  completedTime: '00:00',
  estimatedDropLength: 0,
  dropType: '',
  locateCompleteDate: '',
  handHoleAddress: '',
  spliceCaseType: '',
  auditingSpliceCase: '',
  createSpliceCaseRehung: false,
  singleFusionSpliceQty: 0,
  ribbonSpliceQty: 0,
  ribbonizedCablesQty: 0,
  actualDropLength: 0,
  yardSignPlaced: false,
  lightSignalStrength: '',
  speedTestURL: '',
  additionalRooms: 0,
  WOComplete: false,
  invoiceNumber: '',
  additionalNotes: '',
};

function Connectorized({ WorkOrderID, closeEditModal }) {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.session);
  const sessionFromReact = JSON.stringify(currentUser);

  const [formData, setFormData] = useState(defaultFormData);
  const [spliceCaseTypes, setSpliceCaseTypes] = useState([]);
  const [dropTypes, setDropTypes] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    arrivalDate,
    arrivalTime,
    completedDate,
    completedTime,
    dropType,
    locateCompleteDate,
    handHoleAddress,
    spliceCaseType,
    auditingSpliceCase,
    createSpliceCaseRehung,
    singleFusionSpliceQty,
    ribbonSpliceQty,
    ribbonizedCablesQty,
    actualDropLength,
    estimatedDropLength,
    yardSignPlaced,
    lightSignalStrength,
    speedTestURL,
    additionalRooms,
    WOComplete,
    invoiceNumber,
    additionalNotes,
  } = formData;
  const handleSubmit = () => {
    handleMessage('', 'Modal');
    setLoading(true);

    const payload = {};
    payload.WorkOrderID = WorkOrderID;
    // db takes timestamp as HH:MM:mm, the JS component only returns HH:MM by default
    payload.ArrivalOnsite = arrivalDate;
    payload.ArrivalTime = arrivalTime;
    payload.CompletedDate = completedDate;
    payload.CompletedTime = completedTime;
    payload.nDropTypeID = dropType;
    payload.dtLocateComplete = `${locateCompleteDate} 00:00:00`;
    payload.vcHandHoleAddress = handHoleAddress;
    payload.vcSpliceCaseType = spliceCaseType;
    payload.nAuditSpliceCase = auditingSpliceCase;
    payload.nSingleFusionSpliceQty = singleFusionSpliceQty;
    payload.nRibbonSpliceQty = ribbonSpliceQty;
    payload.nRibbonizedCablesQty = ribbonizedCablesQty;
    payload.nDropLengthFt = actualDropLength;
    payload.nYardSignPlaced = yardSignPlaced ? 'on' : 'off';
    payload.vcLightSignalStrength = lightSignalStrength;
    payload.nSpeedTestPerformed = speedTestURL.length ? 1 : 0;
    payload.SpeedTestURL = speedTestURL;
    payload.nAdditionalRooms = additionalRooms;
    payload.vcInvoiceNum = invoiceNumber;
    payload.txtNotes = additionalNotes;
    payload.nSpawnRehang = createSpliceCaseRehung;
    payload.WOComplete = WOComplete ? 'on' : 'off';

    updateCheckLists(sessionFromReact, payload)
      .then((response) => {
        if (response && response.toLowerCase().includes('error')) {
          handleMessage(response, 'Modal');
        } else {
          handleMessage('Updated successfully', 'Modal');
          closeEditModal();
        }
      })
      .catch((error) => {
        if (error.status === 401) {
          dispatch(logout(error));
        }
      })
      .then(() => { setLoading(false); });
  };

  const renderSpliceCaseType = () => {
    const options = spliceCaseTypes.map((obj) => {
      const { vcType } = obj;
      return (
        <option
          key={`${vcType}Option`}
          value={vcType}
        >
          {vcType}
        </option>
      );
    });

    return (
      <tr className="dataBlock">
        <th>
          <p className="centerContent">Splice Case Type:</p>
        </th>
        <td className="centerContent">
          <select
            value={spliceCaseType}
            onChange={(e) => setFormData({ ...formData, spliceCaseType: e.target.value })}
          >
            <option value="">Select One</option>
            {options}
          </select>
        </td>
      </tr>
    );
  };

  const renderDropType = () => {
    if (!dropTypes.length) {
      return <h1>No Droptyes available</h1>;
    }
    return dropTypes.map((dp) => (
      <option
        key={`${dp.nCount}Option`}
        value={dp.nCount}
      >
        {dp.vcType}
      </option>
    ));
  };

  const renderAdditionalRooms = () => {
    const options = [<option value={0}>0 - No additional charge</option>];
    for (let i = 1; i < 100; i += 1) {
      options.push(<option value={i}>{i}</option>);
    }
    return (
      <tr className="dataBlock">
        <th className="dataBlock">
          <p className="centerContent">Additional Rooms</p>
        </th>
        <td className="centerContent">
          <select
            value={additionalRooms}
            onChange={(e) => setFormData({ ...formData, additionalRooms: e.target.value })}
          >
            {options}
          </select>
        </td>
      </tr>
    );
  };

  useEffect(() => {
    let isMounted = true;
    getSpliceCaseTypes(sessionFromReact)
      .then((response) => {
        setSpliceCaseTypes(JSON.parse(response));
      })
      .catch((error) => {
        // session related error
        if (error.status === 401) {
          dispatch(logout(error));
        }
      });
    getFiberDropTypesWorkOrders(sessionFromReact, 'connectorized')
      .then((response) => {
        if (!isMounted) {
          return;
        }
        setDropTypes(Object.values(JSON.parse(response)));
      })
      .catch((error) => {
        // session related error
        if (error.status === 401) {
          dispatch(logout(error));
        }
      });
    getFiberConnectorizedInstallWorkOrderDetails(sessionFromReact, WorkOrderID)
      .then((response) => {
        const {
          tsArrivalOnsite,
          tsWorkComplete,
          nDropTypeID,
          dtLocateComplete,
          vcHandHoleAddress,
          vcSpliceCaseType,
          nAuditSpliceCase,
          nSingleFusionSpliceQty,
          nRibbonSpliceQty,
          nRibbonizedCablesQty,
          nDropLengthFt,
          nYardSignPlaced,
          vcLightSignalStrength,
          txtSpeedTestURL,
          nAdditionalRooms,
          vcInvoiceNum,
          txtNotes,
          vcStatus,
          nOrderID,
        } = JSON.parse(response);
        const [arrivalDateFromAPI, arrivalTimeFromAPI] = (tsArrivalOnsite || '').split(' ');
        const [completedDateFromAPI, completedTimeFromAPI] = (tsWorkComplete || '').split(' ');

        const newFormData = {
          ...defaultFormData,
          arrivalDate: arrivalDateFromAPI || '',
          arrivalTime: (arrivalTimeFromAPI || '00:00').substring(0, 5), // PHP returns HH:MM:SS while JS takes HH:MM
          completedDate: completedDateFromAPI || '',
          completedTime: (completedTimeFromAPI || '00:00').substring(0, 5),
          dropType: nDropTypeID || '',
          locateCompleteDate: (dtLocateComplete || '').split(' ')[0],
          handHoleAddress: vcHandHoleAddress || '',
          spliceCaseType: vcSpliceCaseType || '',
          auditingSpliceCase: nAuditSpliceCase || '',
          singleFusionSpliceQty: nSingleFusionSpliceQty || 0,
          ribbonSpliceQty: nRibbonSpliceQty || 0,
          ribbonizedCablesQty: nRibbonizedCablesQty || 0,
          actualDropLength: nDropLengthFt || 0,
          yardSignPlaced: nYardSignPlaced || 0,
          lightSignalStrength: vcLightSignalStrength || '',
          speedTestURL: txtSpeedTestURL || '',
          additionalRooms: nAdditionalRooms || 0,
          invoiceNumber: vcInvoiceNum || '',
          additionalNotes: txtNotes || '',
          WOComplete: vcStatus === 'Complete',
          nOrderID,
        };

        setFormData(newFormData);
        disableInputs(vcStatus === 'Complete');
      })
      .catch((error) => {
        console.error(error);
      });
    return () => { isMounted = false; };
  }, [WorkOrderID]);

  useEffect(() => {
    if (!formData.nOrderID) {
      return;
    }
    getEstimatedDropLength(sessionFromReact, formData.nOrderID)
      .then((response) => {
        setFormData({ ...formData, estimatedDropLength: response });
      })
      .catch((error) => {
        console.error(error);
      });
  }, [formData.nOrderID]);

  return (
    <table className="editWOForm darkergray">
      <tbody>
        <tr>
          <th
            colSpan={2}
            className="title"
          >
            <h3 className="centerContent">Onsite Checklist - Fiber Connectorized Install</h3>
          </th>
        </tr>
        <tr className="dataBlock">
          <td className="centerContent">
            <p>Date/Time of Arrival Onsite:</p>
          </td>
          <td className="centerContent">
            <input
              className="width50Percent"
              type="date"
              value={arrivalDate}
              onChange={(e) => setFormData({ ...formData, arrivalDate: e.target.value })}
              placeholder="yyyy-mm-dd"
            />
            <input
              className="width50Percent"
              type="time"
              value={arrivalTime}
              onChange={(e) => setFormData({ ...formData, arrivalTime: e.target.value })}
            />
          </td>
        </tr>
        <tr className="dataBlock">
          <td className="centerContent">
            <p>Date/Time of Work Completed</p>
          </td>
          <td className="centerContent">
            <input
              className="width50Percent"
              type="date"
              value={completedDate}
              onChange={(e) => setFormData({ ...formData, completedDate: e.target.value })}
              placeholder="yyyy-mm-dd"
            />
            <input
              className="width50Percent"
              type="time"
              value={completedTime}
              onChange={(e) => setFormData({ ...formData, completedTime: e.target.value })}
            />
          </td>
        </tr>
        <tr className="dataBlock">
          <th className="dataBlock">
            <p className="centerContent">Drop Type</p>
          </th>
          <td className="centerContent">
            <select
              value={dropType}
              onChange={(e) => setFormData({ ...formData, dropType: e.target.value })}
            >
              <option value={0}>Please Select One</option>
              {renderDropType()}
            </select>
          </td>
        </tr>
        <tr className="dataBlock">
          <td className="centerContent">
            <p>Locates ready on</p>
          </td>
          <td className="centerContent">
            <input
              className="width50Percent"
              type="date"
              value={locateCompleteDate}
              onChange={(e) => setFormData({ ...formData, locateCompleteDate: e.target.value })}
              placeholder="yyyy-mm-dd"
            />
          </td>
        </tr>
        <tr className="dataBlock">
          <th>Assembly Units</th>
        </tr>
        <tr className="centerContent">
          <td>
            <AssemblyUnits
              WOId={WorkOrderID}
              completed={WOComplete}
              className="lightgray"
            />
          </td>
        </tr>
        <tr className="dataBlock">
          <th>
            <p className="centerContent">Hand Hole Address:</p>
          </th>
          <td className="centerContent">
            <input
              type="text"
              className="width100Percent"
              value={handHoleAddress}
              onChange={(e) => setFormData({ ...formData, handHoleAddress: e.target.value })}
            />
          </td>
        </tr>
        {renderSpliceCaseType()}
        <tr className="dataBlock">
          <th>
            <p className="centerContent">Are you Auditing Splice Case?</p>
          </th>
          <td className="centerContent">
            <select value={auditingSpliceCase} onChange={(e) => setFormData({ ...formData, auditingSpliceCase: e.target.value })}>
              <option value="0">NO</option>
              <option value="1">Yes</option>
            </select>
          </td>
        </tr>
        <tr className="dataBlock">
          <td className="centerContent">
            (One time option, not persisted in the checklist)
          </td>
        </tr>
        <tr className="dataBlock">
          <td className="centerContent">
            <p className="centerContent"> Create Aerial Rehang WO?</p>
            <label className="containerCheckbox centerContent">
              Yes
              <input
                type="checkbox"
                checked={createSpliceCaseRehung}
                onChange={(e) => setFormData({ ...formData, createSpliceCaseRehung: e.target.checked })}
              />
              <span className="checkmark" />
            </label>
          </td>
        </tr>
        <tr className="dataBlock">
          <td className="centerContent">
            <p className="centerContent">Single Fusion Splice(s) Quantity:</p>
            <input
              className="width25Percent"
              type="number"
              min="0"
              value={singleFusionSpliceQty}
              onChange={(e) => setFormData({ ...formData, singleFusionSpliceQty: e.target.value })}
            />
          </td>
        </tr>
        <tr className="dataBlock">
          <th>
            <p className="centerContent">Ribbon Splice(s) Quantity:</p>
          </th>
          <td className="centerContent">
            <input
              className="width100Percent"
              type="number"
              min="0"
              value={ribbonSpliceQty}
              onChange={(e) => setFormData({ ...formData, ribbonSpliceQty: e.target.value })}
            />
          </td>
        </tr>
        <tr className="dataBlock">
          <th>
            <p className="centerContent">Ribbonized Cables Quantity:</p>
          </th>
          <td className="centerContent">
            <input
              className="width100Percent"
              type="number"
              min="0"
              value={ribbonizedCablesQty}
              onChange={(e) => setFormData({ ...formData, ribbonizedCablesQty: e.target.value })}
            />
          </td>
        </tr>
        <tr className="dataBlock">
          <th>
            <p className="centerContent">Actual Drop Length(ft.):</p>
          </th>
          <td className="centerContent">
            <select
              onChange={(e) => setFormData({ ...formData, actualDropLength: e.target.value })}
              value={actualDropLength}
            >
              <option value="0">0</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="150">150</option>
              <option value="200">200</option>
              <option value="250">250</option>
              <option value="300">300</option>
              <option value="350">350</option>
              <option value="400">400</option>
              <option value="450">450</option>
              <option value="500">500</option>
              <option value="600">600</option>
              <option value="700">700</option>
              <option value="800">800</option>
              <option value="900">900</option>
              <option value="1000">1000</option>
            </select>
          </td>
        </tr>
        <tr className="dataBlock">
          <th>
            <p className="centerContent">Estimated Drop Length(ft.):</p>
          </th>
          <td className="centerContent">
            <input type="text" value={estimatedDropLength} disabled />
          </td>
        </tr>
        <tr className="checkBoxes">
          <td className="lineCheck">
            <label htmlFor="yardSign" className="marginAuto">
              Yard Sign Placed?
              <input
                id="yardSign"
                type="checkbox"
                checked={yardSignPlaced}
                onChange={() => setFormData({ ...formData, yardSignPlaced: !yardSignPlaced })}
              />
            </label>
          </td>
        </tr>
        <tr className="dataBlock">
          <th className="centerContent">
            <p>Light/Signal Strength (dbm):</p>
          </th>
          <td className="centerContent">
            <input
              value={lightSignalStrength}
              onChange={(e) => setFormData({ ...formData, lightSignalStrength: e.target.value })}
            />
          </td>
        </tr>
        <tr className="dataBlock">
          <td className="centerContent paddingTop10">
            <p>Speedtest.net Result Image URL:</p>
          </td>
          <td className="centerContent">
            <input
              className="speedTestBlock"
              value={speedTestURL}
              onChange={(e) => setFormData({ ...formData, speedTestURL: e.target.value })}
              placeholder="http://www.speedtest.net/result/4173155906.png"
            />
          </td>
        </tr>
        {renderAdditionalRooms()}
        <tr className="dataBlock">
          <th>
            <p className="centerContent">Invoice Number</p>
          </th>
          <td className="centerContent">
            <input
              type="text"
              className="width100Percent"
              value={invoiceNumber}
              onChange={(e) => setFormData({ ...formData, invoiceNumber: e.target.value })}
            />
          </td>
        </tr>
        <tr className="dataBlock">
          <th>
            <p className="centerContent">Additional Notes:</p>
          </th>
          <td className="centerContent">
            <textarea
              value={additionalNotes}
              onChange={(e) => setFormData({ ...formData, additionalNotes: e.target.value })}
              className="width100Percent"
              rows="5"
            />
          </td>
        </tr>
        <tr className="checkBoxes">
          <td className="lineCheck">
            <label htmlFor="complete">Complete Work Order?</label>
            <input id="complete" type="checkbox" checked={WOComplete} onChange={() => setFormData({ ...formData, WOComplete: !WOComplete })} />
          </td>
        </tr>
        <tr className="dataBlock">
          <td className="update-div centerContent" colSpan={2}>
            {loading ? <Loading /> : <button type="button" onClick={() => preSubmitChecker(WOComplete, handleSubmit)}>Update Checklist</button>}
          </td>
        </tr>
        <tr>
          <td>
            <div id="msgDivModal" />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default Connectorized;
