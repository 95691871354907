import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
// import logger from 'redux-logger';

import rootReducer from '../reducers/rootReducer';

const defaultState = {
  session: {
    currentUser: {},
  },
};

const configureStore = (preloadedState = defaultState) => (
  createStore(rootReducer, preloadedState, applyMiddleware(thunk))
  // for debugging:
  // createStore(rootReducer, preloadedState, applyMiddleware(thunk, logger))
);

export default configureStore;
