import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  getSalesOrderAction,
  logout,
} from '../../../../utils';

import Loading from '../../../shared/Loading';
import WorkOrderContext from './WorkOrderContext';

import styles from './styles.module.scss';

function SalesOrder() {
  const { WOObj } = useContext(WorkOrderContext);
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.session);
  const sessionFromReact = JSON.stringify(currentUser);
  const { WOId } = useParams();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!WOObj.SalesOrderDetails) {
      return undefined;
    }
    let isMounted = true;
    setLoading(true);
    getSalesOrderAction(sessionFromReact, WOId, WOObj.SalesOrderDetails.nOrderID)
      .then((response) => {
        if (!isMounted) {
          return;
        }
        document.getElementById('SOHTMLHolder').innerHTML = response;
      })
      .catch((error) => {
        // session related error
        if (error.status === 401) {
          dispatch(logout(error));
        }
      })
      .then(() => { setLoading(false); });
    return () => { isMounted = false; };
    // wait until the WOObj is fetched
  }, [WOObj]);

  return (
    <div className={styles.SalesOrder}>
      {loading ? <Loading /> : null}
      <div id="SOHTMLHolder" />
    </div>
  );
}

export default SalesOrder;
