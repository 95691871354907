import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import Swal from 'sweetalert2';

import SearchContext from './SearchContext';
import SearchHome from './SearchHome';
import WorkOrder from './WorkOrder/WorkOrder';

import {
  preCacheWO,
  getAsset,
  getAttachmentList,
  getCircuitsFromSO,
  getCircuitStats,
  getPhysicalPlantConfig,
  getSalesOrderAction,
  getServiceLocationAssets,
  getWorkOrderObject,
  getWorkOrdersAvailableActions,
  searchWorkOrders,
  getAssetEditData,
  logout,
} from '../../../utils';

import { setPusherMsg, showPusher } from '../../../actions/pusherActions';

import styles from './styles.module.scss';
import Loading from '../../shared/Loading';

const MAX_PREFETCH_WO_NUMBER = 5;

function Search() {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.session);
  const sessionFromReact = JSON.stringify(currentUser);
  const { Provider } = SearchContext;
  // null is not yet searched, [] is no result found
  const [results, setResults] = useState(null);
  const [assignedWOs, setAssignedWOs] = useState([]);
  const [loading, setLoading] = useState(false);

  const context = {
    results,
    setResults,
  };

  useEffect(() => {
    const formData = {};
    formData.ByAssignment = '1';
    formData.ShowPendingAssigned = '0';
    // pre-fetch all assigned WOs' data for offline use
    setLoading(true);
    searchWorkOrders(sessionFromReact, formData)
      .then((response) => {
        setAssignedWOs(JSON.parse(response));
      })
      .catch((error) => {
        // session related error
        if (error.status === 401) {
          dispatch(logout(error));
        }
      })
      .then(() => { setLoading(false); });
  }, []);

  const handlePrefetch = async () => {
    if (navigator.onLine) {
      if (!assignedWOs.length) {
        return;
      }
      const calls = {
        getAsset,
        getAttachmentList,
        getCircuitsFromSO,
        getCircuitStats,
        getPhysicalPlantConfig,
        getSalesOrderAction,
        getServiceLocationAssets,
        getWorkOrderObject,
        getWorkOrdersAvailableActions,
        getAssetEditData,
      };
      dispatch(showPusher());
      const prefetchLoadingMsg = `First ${MAX_PREFETCH_WO_NUMBER} assigned work orders and relevant actions will be cached for offline use. Now fetching details of the orders. This can take a few minutes. If you have internet connection you can disregard this message and start searching your work orders.`;
      const prefetchCompleteMsg = 'You\'re all set! Note that Sales Order page and PDF files aren\'t precached.';
      dispatch(setPusherMsg(prefetchLoadingMsg));
      const promises = assignedWOs.map((wo, i) => {
        if (i < MAX_PREFETCH_WO_NUMBER) {
          return preCacheWO(sessionFromReact, wo.nCount, calls);
        }
        return Promise.resolve();
      });
      const subpromises = [];
      await Promise.allSettled(promises).then((ps) => {
        // ps looks like [{ state: 'fulfilled', value: Array(3) }, { ... }]
        subpromises.push(...ps);
      });
      await Promise.allSettled(subpromises.map((p) => p.value)).then(() => {
        dispatch(setPusherMsg(prefetchCompleteMsg));
        Swal.fire({
          icon: 'success',
          text: prefetchCompleteMsg,
        });
      });
    }
    if (!navigator.onLine) {
      Swal.fire({
        text: 'Cannot prefetch while offline!',
      });
    }
  };

  return (
    <Provider value={context}>
      <button type="button" onClick={handlePrefetch} className={styles.prefetch}>Prefetch latest 5 work orders for offline use</button>
      {loading ? <Loading /> : null}
      <div className={styles.Search}>
        <Switch>
          <Route path="/search/:WOId" component={WorkOrder} />
          <Route path="/search" component={SearchHome} />
          <Route path="/" component={SearchHome} />
        </Switch>
      </div>
    </Provider>
  );
}

export default Search;
