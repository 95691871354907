import $ from 'jquery';

import { BACKEND_API } from './constants';

const postToReportsBack = (data) => $.ajax({
  method: 'POST',
  url: `${BACKEND_API}/backend/functions/reportsBack/reportsBack.php`,
  data,
});

export const viewWholesaleReport = (sessionFromReact, reportName, formData) => {
  const data = {
    sessionFromReact,
    action: 'viewWholesaleReport',
    UTILITYID: 69,
    reportName,
    ...formData,
  };
  return postToReportsBack(data);
};

export const viewPhoneReport = (sessionFromReact, reportName) => {
  const data = {
    sessionFromReact,
    action: 'viewPhoneReport',
    UTILITYID: 40,
    reportName,
  };
  return postToReportsBack(data);
};

export const viewBusinessReport = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'viewBusinessReport',
    UTILITYID: 40,
    ...formData,
  };
  return postToReportsBack(data);
};

export const viewDownloadBusinessReport = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'viewDownloadBusinessReport',
    UTILITYID: 40,
    ...formData,
  };
  return postToReportsBack(data);
};

export const downloadBusinessReport = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'downloadBusinessReport',
    UTILITYID: 40,
    ...formData,
  };
  return postToReportsBack(data);
};
