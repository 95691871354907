import $ from 'jquery';
import Swal from 'sweetalert2';
import { showPusher, setPusherMsg } from '../actions/pusherActions';
import { BACKEND_API } from './constants';

const { google } = window;

export const fireError = (text) => {
  Swal.fire({
    icon: 'error',
    text,
  });
};

export const handleMessage = (msg, id = '', showAlert = false) => {
  const msgDiv = document.getElementById(`msgDiv${id}`);
  if (msgDiv) {
    // contains html tag so set it manually
    msgDiv.innerHTML = msg;
    if (showAlert) {
      Swal.fire({
        icon: 'success',
        text: msg,
      });
    }
  } else {
    console.log(`msg div not found, id: ${id}, msg: ${msg}`);
  }
};

export const formatPhoneNumber = (phone) => (phone ? `(${phone.substr(0, 3)})${phone.substr(3, 3)}-${phone.substr(6)}` : '');

export const centerMarker = (markers, infoWindows, map, id, ftLata, ftLong) => {
  infoWindows[id].open(map, markers[id]);
  const myLatlng = new google.maps.LatLng(parseFloat(ftLata, 10), parseFloat(ftLong, 10));
  map.panTo(myLatlng);
  window.parent.scroll(0, 0);
};

export const handleAddressStr = (obj) => {
  // NOTE: there's another obj type that use 'Address' instead, not handled here
  const {
    vcAddress,
    vcAddress2,
    vcCity,
    vcState,
    vcZip,
    vcZipFour,
  } = obj;
  const addr = vcAddress2 ? `${vcAddress} ${vcAddress2},` : `${vcAddress},`;
  const zip = vcZipFour ? `${vcZip} - ${vcZipFour}` : vcZip;
  return `${addr} ${vcCity} ${vcState} ${zip}`;
};

export const saveLocalStorage = (key, obj) => {
  const objStr = JSON.stringify(obj);
  if (objStr) {
    try {
      localStorage.setItem(key, objStr);
    } catch (e) {
      console.log(e);
      // storage full that it exceeded quota
      localStorage.removeItem(key);
    }
  }
};

/**
 * @param {object} data — The request body of a request, contains sessionFromReact + other needed params
 * @param {string} url — The request endpoint on the Portal backend
 * @param {string} method — The request type, default to POST if going through provapp1 / provapp2
 * @returns {void} Returns nothing
 */

export const cacheResponseHelper = async (data, url, method = 'POST') => {
  const requestCopy = { url, method, data: { ...data } };
  const cachedResponseKeys = localStorage.getItem('cachedResponseKeys') ? JSON.parse(localStorage.getItem('cachedResponseKeys')) : [];
  // old cache keys as object, clear client side cache, remove this logic in a later version.
  if (localStorage.getItem('cachedKeys')) {
    localStorage.removeItem('cachedKeys');
  }
  let res;
  const currentLogTimeMillis = parseInt(localStorage.getItem('currentLogTimeMillis') || '0', 10);
  if (currentLogTimeMillis < Date.now() - 3600000) {
    await $.ajax({
      url: `${BACKEND_API}/backend/functions/sessionBack/sessionBack.php`,
      method: 'POST',
      data: { sessionFromReact: data.sessionFromReact, action: 'log' },
      success: (response) => { saveLocalStorage('currentLogTimeMillis', response); },
      error: (e) => console.log(e),
    });
  }
  // this object can be too big due to existing tech debt
  // try to reassign the value when we kick off the request again
  delete requestCopy.data.sessionFromReact;
  const cachedKey = JSON.stringify(requestCopy);
  try {
    await $.ajax({
      url,
      method,
      data,
      success: (response) => {
        if (!cachedResponseKeys.find((obj) => obj[cachedKey])) {
          cachedResponseKeys.push({ [cachedKey]: response });
        }
        res = response;
        // store the last 50 items
        saveLocalStorage('cachedResponseKeys', cachedResponseKeys.slice(-50));
      },
      error: (response) => {
        // request failed, attempt to get the response from local cache
        const found = cachedResponseKeys.findIndex((obj) => obj[cachedKey]);
        if (found !== -1) {
          response.statusText = 'Cached Response';
          response.responseText = cachedResponseKeys[found][cachedKey];
          res = cachedResponseKeys[found][cachedKey];
        }
        throw Error(response.statusText);
      },
    });
  } catch (e) {
    throw Error(e);
  }

  return res;
};

export const handlePusherMsgForRequests = () => (dispatch) => {
  if (!navigator.onLine) {
    dispatch(showPusher());
    dispatch(setPusherMsg('You are not connected to the internet. The response is cached from local storage.'));
  }
};

export const preCacheWO = async (sessionFromReact, WOId, calls) => {
  const {
    getAsset,
    getAttachmentList,
    getCircuitsFromSO,
    getPhysicalPlantConfig,
    getSalesOrderAction,
    getServiceLocationAssets,
    getWorkOrderObject,
    getWorkOrdersAvailableActions,
    getAssetEditData,
  } = calls;

  // TODO: Create a backend that returns everything needed here to avoid nested ajax call
  return getWorkOrderObject(sessionFromReact, WOId)
    .then(async (response) => {
      const WOObj = JSON.parse(response);
      if (WOObj.SalesOrderDetails) {
        await getPhysicalPlantConfig(sessionFromReact, WOObj.SalesOrderDetails.nOrderID);
        await getSalesOrderAction(sessionFromReact, WOId, WOObj.SalesOrderDetails.nOrderID);
      }
      const { ServiceLocationID } = WOObj.ServiceLocationDetails;
      await getCircuitsFromSO(sessionFromReact, ServiceLocationID);
      await getAttachmentList(sessionFromReact, WOId);
      return getServiceLocationAssets(sessionFromReact, ServiceLocationID)
        .then(async (assets) => {
          const getActionsPromises = JSON.parse(assets).map(async (asset) => {
            await getAsset(sessionFromReact, asset.nAssetID);
            await getAssetEditData(sessionFromReact, asset.nAssetID);
            return getWorkOrdersAvailableActions(
              sessionFromReact,
              WOId,
              asset.vcAssetID,
            );
          });
          return Promise.allSettled(getActionsPromises);
        });
    });
};

export const initializeSavedAssets = () => {
  const prevSavedAssets = localStorage.getItem('savedAssets');
  return prevSavedAssets ? JSON.parse(prevSavedAssets) : {};
};

const postToFunction = (data) => $.ajax({
  url: `${BACKEND_API}/backend/functions/functions.php`,
  method: 'POST',
  data,
  success: (response) => response,
  error: (error) => error,
});

// to invoke functions dynamically
// currently only for business customers
export const triggerBusinessCustomerFuction = (sessionFromReact, vcCustomerID, functionName, options = {}) => {
  const data = {
    sessionFromReact,
    action: 'trigger_business_customer_fuction',
    vcCustomerID,
    functionName,
    options,
    UTILITYID: 15,
  };
  return postToFunction(data);
};

export const censorString = (val = ' ', censor = true) => {
  if (!censor) {
    return val;
  }
  const str = val.toString();
  if (str.length < 1) {
    return str;
  }
  let censoredString = '';
  for (let i = 0; i < str.length - 1; i += 1) {
    if (i > 14) {
      censoredString += str[i];
    } else if (str[i] === ' ') {
      censoredString += ' ';
    } else {
      censoredString += '*';
    }
  }
  return `${censoredString}${str[str.length - 1]}`;
};
