import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';

import {
  updateCheckLists,
  getFiberWorkOrderDetailsBaseHelper,
  logout,
  handleMessage,
} from '../../../../utils';
import { disableInputs } from '../../utils';
import { preSubmitChecker } from '../functions';
import Loading from '../../Loading';

const defaultFormData = {
  yardSignPlaced: false,
  additionalRooms: 0,
  arrivalDate: '',
  arrivalTime: '00:00',
  completedDate: '',
  completedTime: '00:00',
  lightSignalStrength: '',
  speedTestURL: '',
  additionalNotes: '',
  WOComplete: false,
};

function TVInstall({
  WorkOrderID,
  closeEditModal,
  type,
  templateType,
}) {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.session);
  const sessionFromReact = JSON.stringify(currentUser);
  const [formData, setFormData] = useState(defaultFormData);
  const [loading, setLoading] = useState(false);

  const {
    yardSignPlaced,
    additionalRooms,
    arrivalDate,
    arrivalTime,
    completedDate,
    completedTime,
    lightSignalStrength,
    speedTestURL,
    additionalNotes,
    WOComplete,
  } = formData;

  const handleSubmit = () => {
    handleMessage('', 'Modal');
    if (!speedTestURL) {
      Swal.fire({
        icon: 'warning',
        text: 'Oops, looks like you haven\'t attached the speed test URL yet, please put a proper value and try again.',
      });
      return;
    }
    setLoading(true);
    const payload = {};
    payload.WorkOrderID = WorkOrderID;
    if (type !== 'business') {
      payload.YardSignPlaced = yardSignPlaced ? 'on' : 'off';
      payload.nAdditionalRooms = additionalRooms;
    }
    payload.ArrivalOnsite = arrivalDate;
    payload.ArrivalTime = arrivalTime;
    payload.CompletedDate = completedDate;
    payload.CompletedTime = completedTime;
    payload.LightSignalStrength = lightSignalStrength;
    payload.SpeedTestURL = speedTestURL;
    payload.AdditionalNotes = additionalNotes;
    payload.WOComplete = WOComplete ? 'on' : 'off';
    updateCheckLists(sessionFromReact, payload)
      .then((response) => {
        if (response && response.toLowerCase().includes('error')) {
          handleMessage(response, 'Modal');
        } else {
          handleMessage('Updated successfully', 'Modal');
          closeEditModal();
        }
      })
      .catch((error) => {
        // session related error
        if (error.status === 401) {
          dispatch(logout(error));
        }
      })
      .then(() => { setLoading(false); });
  };

  const renderAdditionalRoomsSelect = () => {
    const options = [];
    for (let i = 1; i <= 100; i += 1) {
      options.push(<option value={`${i}`} key={`${i}Option`}>{i}</option>);
    }
    return (
      <select value={additionalRooms} onChange={(e) => setFormData({ ...formData, additionalRooms: e.target.value })}>
        <option value="0" key="0Option"> 0 - No additional charge</option>
        {options}
      </select>
    );
  };

  useEffect(() => {
    let isMounted = true;
    getFiberWorkOrderDetailsBaseHelper(sessionFromReact, WorkOrderID, templateType)
      .then((response) => {
        if (!response || !isMounted) {
          return;
        }
        const parsed = JSON.parse(response);
        const {
          nYardSignPlaced,
          nAdditionalRooms,
          tsArrivalOnsite,
          tsWorkComplete,
          vcLightSignalStrength,
          txtSpeedTestURL,
          txtNotes,
          vcStatus,
        } = parsed;
        const [date, time] = (tsArrivalOnsite || '').split(' ');
        const [completeDate, completeTime] = (tsWorkComplete || '').split(' ');
        const completed = vcStatus === 'Complete';

        const newFormData = {
          ...formData,
          setYardSignPlaced: nYardSignPlaced === 'on',
          setAdditionalRooms: nAdditionalRooms || 0,
          setArrivalDate: date || '',
          setArrivalTime: time || '00:00',
          setCompletedDate: completeDate || '',
          setCompletedTime: completeTime || '00:00',
          setLightSignalStrength: vcLightSignalStrength || '',
          setSpeedTestURL: txtSpeedTestURL || '',
          setAdditionalNotes: txtNotes || '',
          WOComplete: completed,
        };
        disableInputs(completed);
        setFormData(newFormData);
      })
      .catch((error) => {
        // session related error
        if (error.status === 401) {
          dispatch(logout(error));
        }
      });
    return () => { isMounted = false; };
  }, [WorkOrderID]);

  return (
    <table className="editWOForm darkergray">
      <tbody>
        <tr className="centerContent">
          <th colSpan={2} className="title"><h3 className="centerContent">Onsite Checklist - TV Install</h3></th>
        </tr>
        {type === 'business' ? null : (
          <>
            <tr className="checkBoxes">
              <td className="lineCheck">
                <label htmlFor="yardSign">Yard Sign Placed?</label>
                <td>
                  <input id="yardSign" type="checkbox" value={yardSignPlaced} onChange={() => setFormData({ ...formData, yardSignPlaced: !yardSignPlaced })} />
                </td>
              </td>
            </tr>
            <tr className="dataBlock">
              <th className="dataBlock">
                <p className="centerContent">
                  Additional Rooms:
                </p>
                <p className="centerContent">
                  (additional $75 charge applied for each room)
                </p>
              </th>
              <td className="centerContent">{renderAdditionalRoomsSelect()}</td>
            </tr>
          </>
        )}
        <tr className="dataBlock">
          <td className="centerContent"><p>Date/Time of Arrival Onsite:</p></td>
          <td className="centerContent">
            <input className="width50Percent" type="date" value={arrivalDate} onChange={(e) => setFormData({ ...formData, arrivalDate: e.target.value })} placeholder="yyyy-mm-dd" />
            <input className="width50Percent" type="time" value={arrivalTime} onChange={(e) => setFormData({ ...formData, arrivalTime: e.target.value })} />
          </td>
        </tr>
        <tr className="dataBlock">
          <td className="centerContent"><p>Date/Time of Work Completed</p></td>
          <td className="centerContent">
            <input className="width50Percent" type="date" value={completedDate} onChange={(e) => setFormData({ ...formData, completedDate: e.target.value })} placeholder="yyyy-mm-dd" />
            <input className="width50Percent" type="time" value={completedTime} onChange={(e) => setFormData({ ...formData, completedTime: e.target.value })} />
          </td>
        </tr>
        <tr className="dataBlock">
          <th className="centerContent"><p>Light/Signal Strength (dbm):</p></th>
          <td className="centerContent">
            <input
              value={lightSignalStrength}
              onChange={(e) => setFormData({ ...formData, lightSignalStrength: e.target.value })}
            />
          </td>
        </tr>
        <tr className="dataBlock">
          <td className="centerContent paddingTop10"><p>Speedtest.net Result Image URL:</p></td>
          <td className="centerContent">
            <input
              className="speedTestBlock"
              value={speedTestURL}
              onChange={(e) => setFormData({ ...formData, speedTestURL: e.target.value })}
              placeholder="http://www.speedtest.net/result/4173155906.png"
            />
          </td>
        </tr>
        <tr className="dataBlock">
          <td className="centerContent"><p>Additional Notes:</p></td>
          <td className="centerContent">
            <textarea
              className="textBlockSize"
              value={additionalNotes}
              onChange={(e) => setFormData({ ...formData, additionalNotes: e.target.value })}
            />
          </td>
        </tr>
        <tr className="checkBoxes">
          <td className="lineCheck">
            <label htmlFor="complete">Complete Work Order?</label>
            <input id="complete" type="checkbox" checked={WOComplete} onChange={() => setFormData({ ...formData, WOComplete: !WOComplete })} />
          </td>
        </tr>
        <tr className="dataBlock">
          <td className="update-div centerContent" colSpan={2}>
            {loading ? <Loading /> : <button type="button" onClick={() => preSubmitChecker(WOComplete, handleSubmit)}>Update Checklist</button>}
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <div id="msgDivModal" />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default TVInstall;
