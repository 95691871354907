import { RECEIVE_SESSION_ERRORS, CLEAR_SESSION_ERRORS } from '../actions';

const sessionErrorsReducer = (state = [], action = {}) => {
  Object.freeze(state);
  const updatedState = { ...state };
  switch (action.type) {
    case RECEIVE_SESSION_ERRORS:
      updatedState.sessionError = action.errors;
      break;
    case CLEAR_SESSION_ERRORS:
      delete updatedState.sessionError;
      break;
    default:
  }
  return updatedState;
};

export default sessionErrorsReducer;
