import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  updateCheckLists,
  getFiberWorkOrderDetailsBaseHelper,
  logout,
  handleMessage,
} from '../../../../utils';
import { disableInputs } from '../../utils';
import { preSubmitChecker } from '../functions';
import Loading from '../../Loading';

const showRehungList = [
  'Backbone Splice',
];

function Base({
  WorkOrderID,
  closeEditModal,
  templateType,
  title,
}) {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.session);
  const sessionFromReact = JSON.stringify(currentUser);
  const [arrivalDate, setArrivalDate] = useState('');
  const [arrivalTime, setArrivalTime] = useState('00:00');
  const [completedDate, setCompletedDate] = useState('');
  const [completedTime, setCompletedTime] = useState('00:00');
  const [additionalNotes, setAdditionalNotes] = useState('');
  const [WOComplete, setWOComplete] = useState(false);
  const [spliceCaseRehung, setSpliceCaseRehung] = useState(false);
  const [showRehung, setShowRehung] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    handleMessage('', 'Modal');
    setLoading(true);
    const formData = {};
    formData.WorkOrderID = WorkOrderID;
    formData.ArrivalOnsite = arrivalDate;
    formData.ArrivalTime = arrivalTime;
    formData.CompletedDate = completedDate;
    formData.CompletedTime = completedTime;
    formData.AdditionalNotes = additionalNotes;
    formData.WOComplete = WOComplete ? 'on' : 'off';
    if (showRehung) {
      formData.nSpawnRehang = spliceCaseRehung;
    }
    updateCheckLists(sessionFromReact, formData)
      .then((response) => {
        if (response && response.toLowerCase().includes('error')) {
          handleMessage(response, 'Modal');
        } else {
          handleMessage('Updated successfully', 'Modal');
          closeEditModal();
        }
      })
      .catch((error) => {
        // session related error
        if (error.status === 401) {
          dispatch(logout(error));
        }
      })
      .then(() => { setLoading(false); });
  };

  useEffect(() => {
    let isMounted = true;
    if (showRehungList.includes(title)) {
      setShowRehung(true);
    }
    getFiberWorkOrderDetailsBaseHelper(sessionFromReact, WorkOrderID, templateType)
      .then((response) => {
        if (!response || !isMounted) {
          return;
        }
        const parsed = JSON.parse(response);
        const {
          tsArrivalOnsite,
          tsWorkComplete,
          txtNotes,
          vcStatus,
        } = parsed;
        const [date, time] = (tsArrivalOnsite || '').split(' ');
        const [completeDate, completeTime] = (tsWorkComplete || '').split(' ');
        setArrivalDate(date || '');
        setArrivalTime(time || '00:00');
        setCompletedDate(completeDate || '');
        setCompletedTime(completeTime || '00:00');
        setAdditionalNotes(txtNotes || '');
        const completed = vcStatus === 'Complete' || vcStatus === 'Closed';
        setWOComplete(completed);
        disableInputs(completed);
      })
      .catch((error) => {
        // session related error
        if (error.status === 401) {
          dispatch(logout(error));
        }
      });
    return () => { isMounted = false; };
  }, [WorkOrderID]);

  return (
    <table className="editWOForm darkergray">
      <tbody>
        <tr>
          <th colSpan={2} className="title">
            <h3 className="centerContent">
              {`Onsite Checklist - ${title}`}
            </h3>
          </th>
        </tr>
        <tr className="dataBlock">
          <td className="centerContent"><p>Date/Time of Arrival Onsite:</p></td>
          <td className="centerContent">
            <input className="width50Percent" type="date" value={arrivalDate} onChange={(e) => setArrivalDate(e.target.value)} placeholder="yyyy-mm-dd" />
            <input className="width50Percent" type="time" value={arrivalTime} onChange={(e) => setArrivalTime(e.target.value)} />
          </td>
        </tr>
        <tr className="dataBlock">
          <td className="centerContent"><p>Date/Time of Work Completed</p></td>
          <td className="centerContent">
            <input className="width50Percent" type="date" value={completedDate} onChange={(e) => setCompletedDate(e.target.value)} placeholder="yyyy-mm-dd" />
            <input className="width50Percent" type="time" value={completedTime} onChange={(e) => setCompletedTime(e.target.value)} />
          </td>
        </tr>
        <tr className="dataBlock">
          <td className="centerContent"><p>Additional Notes:</p></td>
          <td className="centerContent">
            <textarea
              className="textBlockSize"
              value={additionalNotes}
              onChange={(e) => setAdditionalNotes(e.target.value)}
            />
          </td>
        </tr>
        <tr className="checkBoxes">
          <td className="lineCheck">
            <label htmlFor="complete">Complete Work Order?</label>
            <input id="complete" type="checkbox" checked={WOComplete} onChange={() => setWOComplete(!WOComplete)} />
          </td>
        </tr>
        {showRehung ? (
          <>
            <br />
            <tr className="dataBlock">
              <td className="centerContent">
                (One time option, not persisted in the checklist)
              </td>
            </tr>
            <tr className="checkBoxes">
              <td className="lineCheck">
                <label htmlFor="SpliceCaseRehung">
                  Create Aerial Rehang WO?
                </label>
                <input id="SpliceCaseRehung" type="checkbox" checked={spliceCaseRehung} onChange={() => setSpliceCaseRehung(!spliceCaseRehung)} />
              </td>
            </tr>
          </>
        ) : null}
        <tr className="dataBlock">
          <td className="update-div centerContent" colSpan={2}>
            {loading ? <Loading /> : <button type="button" onClick={() => preSubmitChecker(WOComplete, handleSubmit)}>Update Checklist</button>}
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <div id="msgDivModal" />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default Base;
