import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  getFiberCoiledWorkOrderDetails,
  updateCheckLists,
  logout,
  handleMessage,
} from '../../../../utils';

import { disableInputs } from '../../utils';
import { preSubmitChecker } from '../functions';
import Loading from '../../Loading';

function Coiled({
  WorkOrderID,
  closeEditModal,
  type,
  templateType = '',
}) {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.session);
  const sessionFromReact = JSON.stringify(currentUser);
  const [boreFootage, setboreFootage] = useState('');
  const [additionalNotes, setAdditionalNotes] = useState('');
  const [WOComplete, setWOComplete] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    handleMessage('', 'Modal');
    setLoading(true);
    const formData = {};
    formData.WorkOrderID = WorkOrderID;
    formData.boreFootage = boreFootage;
    formData.AdditionalNotes = additionalNotes;
    formData.WOComplete = WOComplete ? 'on' : 'off';
    updateCheckLists(sessionFromReact, formData)
      .then((response) => {
        if (response && response.toLowerCase().includes('error')) {
          handleMessage(response, 'Modal');
        } else {
          handleMessage('Updated successfully', 'Modal');
          closeEditModal();
        }
      })
      .catch((error) => {
        // session related error
        if (error.status === 401) {
          dispatch(logout(error));
        }
      })
      .then(() => { setLoading(false); });
  };
  useEffect(() => {
    let isMounted = true;
    const formData = {
      nCount: WorkOrderID,
      type,
      templateType,
    };
    getFiberCoiledWorkOrderDetails(sessionFromReact, formData)
      .then((response) => {
        if (!response || !isMounted) {
          return;
        }
        const parsed = JSON.parse(response);
        const {
          txtNotes,
          vcStatus,
          nBoreNum,
        } = parsed;
        // const [date, time] = (dtDropBuryStart || '').split(' ');
        setboreFootage(nBoreNum || '');
        setAdditionalNotes(txtNotes || '');
        const completed = vcStatus === 'Complete';
        setWOComplete(completed);
        disableInputs(completed);
      })
      .catch((error) => {
        // session related error
        if (error.status === 401) {
          dispatch(logout(error));
        }
      });
    return () => { isMounted = false; };
  }, [WorkOrderID]);

  return (
    <table className="editWOForm darkergray">
      <tbody>
        <tr>
          <th colSpan={2} className="title"><h3 className="centerContent">Onsite Checklist - Drop Coiled</h3></th>
        </tr>
        <tr className="dataBlock">
          <td className="centerContent"><p>Approximate Handhold Location And Notes:</p></td>
          <td className="centerContent">
            <textarea
              className="textBlockSize"
              value={additionalNotes}
              onChange={(e) => setAdditionalNotes(e.target.value)}
            />
          </td>
        </tr>
        <tr className="dataBlock">
          <th className="centerContent"><p>Bore Footage (ft):</p></th>
          <td className="centerContent">
            <input
              value={boreFootage}
              onChange={(e) => setboreFootage(e.target.value)}
            />
          </td>
        </tr>
        <tr className="checkBoxes">
          <td className="lineCheck">
            <label htmlFor="complete">Complete Work Order?</label>
            <input id="complete" type="checkbox" checked={WOComplete} onChange={() => setWOComplete(!WOComplete)} />
          </td>
        </tr>
        <tr className="dataBlock">
          <td className="update-div centerContent" colSpan={2}>
            {loading ? <Loading /> : <button type="button" onClick={() => preSubmitChecker(WOComplete, handleSubmit)}>Update Checklist</button>}
          </td>
        </tr>
        <tr>
          <td>
            <div id="msgDivModal" />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default Coiled;
