import { combineReducers } from 'redux';
import sessionReducer from './sessionReducer';
import sessionErrorsReducer from './sessionErrorsReducer';
import pusherReducer from './pusherReducer';

const rootReducer = combineReducers({
  session: sessionReducer,
  errors: sessionErrorsReducer,
  pusher: pusherReducer,
});

export const defaultState = {
  session: {
    currentUser: {},
  },
  pusher: {
    show: false,
    msg: '',
  },
};

export default rootReducer;
