import React from 'react';
import { Link, useParams } from 'react-router-dom';

function CircuitStats({ circuits }) {
  const { WOId } = useParams();

  const renderCircuits = () => {
    if (!circuits || !circuits.length) {
      return (
        <div>
          No Circuits Found
        </div>
      );
    }
    const cs = circuits.map((circuit) => {
      const {
        SocketRecordID,
        vcSocketCircuitID,
        CircuitDescription,
      } = circuit;

      const pathname = `/search/${WOId}/circuitStats/${SocketRecordID}`;
      return (
        <Link to={`${pathname}`} key={`${SocketRecordID}-div`}>
          <div>
            <span>
              {`${vcSocketCircuitID} : ${CircuitDescription}`}
            </span>
            {/* <button type="button" onClick={() => showCircuit(SocketRecordID)}>
              Show Circuit
            </button> */}
          </div>
        </Link>
      );
    });
    return (
      <div>
        {cs}
      </div>
    );
  };

  return (
    <div>
      {renderCircuits()}
    </div>
  );
}

export default CircuitStats;
