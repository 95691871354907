import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import { getCircuitStats, logout, setTestTone } from '../../../../../utils';

import WorkOrderContext from '../WorkOrderContext';

import styles from './styles.module.scss';

function CircuitStat() {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.session);
  const sessionFromReact = JSON.stringify(currentUser);
  const [stat, setStat] = useState([]);
  const { circuits } = useContext(WorkOrderContext);
  const { socketRecordID } = useParams();
  const found = circuits.find((obj) => obj.SocketRecordID === socketRecordID);
  const ignoreStat = ['nPort', 'nSlot', 'nCount'];

  let serverIP = '';
  let port = '';
  let slot = '';

  const stopTest = () => {
    if (!serverIP || !port || !slot) {
      return;
    }
    const circuitData = {
      testType: '3',
      serverIP,
      port,
      slot,
      oID: `.1.3.6.1.4.1.664.2.753.3.2.1.1.1.${slot}${port}`,
    };
    setTestTone(sessionFromReact, circuitData);
  };

  const testTone = (testType) => {
    if (!serverIP || !port || !slot) {
      return;
    }
    let testText = 'loop';
    if (testType === '2') {
      testText = 'network';
    }
    const circuitData = {
      testType,
      serverIP,
      port,
      slot,
      oID: `.1.3.6.1.4.1.664.2.753.3.2.1.1.1.${slot}${port}`,
    };
    setTestTone(sessionFromReact, circuitData);
    Swal.fire({
      title: `Test tone to ${testText}`,
      allowOutsideClick: false,
      allowEscapeKey: false,
      text: 'Test is running...',
      confirmButtonText: 'Stop test',
    }).then(() => { stopTest(); });
  };

  const renderStats = () => {
    if (!stat || !stat.length) {
      return (
        <div>
          No Circuit Stats Found
        </div>
      );
    }
    let currentTitle = '';
    return stat.map((array) => {
      const [key, value] = array;
      if (key.startsWith('vcServerIP')) {
        serverIP = `${value}`;
      }
      if (key.startsWith('nPort')) {
        port = `${value}`;
      }
      if (key.startsWith('nSlot')) {
        slot = `${value}`;
      }

      if (ignoreStat.includes(array[0])) {
        return null;
      }
      if (typeof value === 'object') {
        return null;
      }
      let displayedKey = `${key}`;
      let title = '';

      if (displayedKey.substring(0, 1) === 'n') {
        displayedKey = displayedKey.slice(1);
      }
      if (displayedKey.substring(0, 2) === 'vc') {
        displayedKey = displayedKey.slice(2);
      }
      if (displayedKey === 'vcShelfSlotPon' || displayedKey === 'nONTNumber') {
        return null;
      }
      if (key.startsWith('nONTSettings')) {
        displayedKey = displayedKey.slice(11);
        if (currentTitle !== 'ONTSettings') {
          title = 'ONT Settings';
          currentTitle = 'ONTSettings';
        }
      }
      if (key.startsWith('vcONTSettings')) {
        displayedKey = displayedKey.slice(11);
        if (currentTitle !== 'ONTSettings') {
          title = 'ONT Settings';
          currentTitle = 'ONTSettings';
        }
      }
      if (key.startsWith('ports')) {
        displayedKey = displayedKey.slice(5);
        if (currentTitle !== 'ports') {
          title = 'Ports';
          currentTitle = 'ports';
        }
      }
      if (key.startsWith('vcONTStatus')) {
        displayedKey = displayedKey.slice(9);
        if (currentTitle !== 'ONTStatus') {
          title = 'ONT Status';
          currentTitle = 'ONTStatus';
        }
      }

      // add a space after the title, $1 refers to the first argument and $2 is the second
      displayedKey = displayedKey.replace(/([a-z])([A-Z])/g, '$1 $2');
      displayedKey = displayedKey.replace(/(ONT|POTS|SNR|FEC|CRC)/g, '$1 ');
      return (
        <div>
          <div className={styles.title}>
            <span>
              {title}
            </span>
          </div>
          <div className={styles.keyValue}>
            <span className={styles.keyDisplay}>
              {displayedKey}
              :
            </span>
            <div className={styles.valDisplay}>
              {value}
            </div>
          </div>
        </div>
      );
    });
  };

  useEffect(() => {
    if (!found) {
      return;
    }
    getCircuitStats(sessionFromReact, found.vcSocketCircuitID)
      .then((response) => {
        setStat(Object.entries(JSON.parse(response).stats).reverse());
      }).catch((error) => {
        // session related error
        if (error.status === 401) {
          dispatch(logout(error));
        }
      });
  }, []);

  return (
    <div className={styles.CircuitStat}>
      <div className={styles.statDisplay} key={`${socketRecordID}-${socketRecordID}-div`}>
        <div className={styles.btnContainer}>
          <div className={styles.leftBtns}>
            <button type="button" onClick={() => { testTone('2'); }}>Tone to network</button>
            <button type="button" onClick={() => { testTone('1'); }}>Tone to loop</button>
          </div>
          <div className={styles.rightBtns}>
            <button type="button" onClick={() => { stopTest(); }}>Stop tone tests</button>
          </div>
        </div>
        {renderStats()}
      </div>
    </div>
  );
}

export default CircuitStat;
