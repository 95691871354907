import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import {
  getServiceLocationAssets,
  logout,
} from '../../../../utils';

import Loading from '../../../shared/Loading';

import styles from './styles.module.scss';

function Assets({ serviceLocationId }) {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.session);
  const sessionFromReact = JSON.stringify(currentUser);
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(false);

  const { pathname } = useLocation();
  const renderAssets = () => {
    if (!assets.length) {
      return <h3>No assets tied to this location</h3>;
    }
    const spans = assets.map((asset) => {
      const {
        nAssetID,
        vcAssetID,
        AssetDescription,
      } = asset;
      return (
        <Link to={`${pathname}/${nAssetID}`} key={`${nAssetID}-div`}>
          <span className={styles.assetLink}>{`${vcAssetID}: ${AssetDescription}`}</span>
        </Link>
      );
    });
    return (
      <div>
        <h3 className={styles.bold}>Assets:</h3>
        {spans}
      </div>
    );
  };

  useEffect(() => {
    setLoading(true);
    getServiceLocationAssets(sessionFromReact, serviceLocationId)
      .then((response) => {
        const parsed = JSON.parse(response);
        if (parsed) {
          setAssets(parsed);
        }
      })
      .catch((error) => {
        // session related error
        if (error.status === 401) {
          dispatch(logout(error));
        }
      })
      .then(() => { setLoading(false); });
  }, []);

  return (
    <div className={styles.Assets}>
      {loading ? <Loading /> : null}
      {renderAssets()}
    </div>
  );
}

export default Assets;
