import React from 'react';

import styles from './styles.module.scss';

function Contacts({ contacts = [] }) {
  if (!contacts.length) {
    return <h1>No Contacts found</h1>;
  }
  const cts = contacts.map((contact) => {
    const {
      ContactCategory,
      ContactName,
      Phone,
      Phone2,
      Email,
      ContactMethodID,
    } = contact;
    return (
      <div key={`${ContactMethodID}-${ContactName}-div`} className={styles.Contact}>
        <div className={styles.contactInfo}>
          <span>{ContactCategory}</span>
          <br />
          <span>{ContactName}</span>
        </div>
        <div className={styles.contactInfo}>
          <span>Phone 1:</span>
          <span>{Phone}</span>
        </div>
        {Phone2 ? (
          <div className={styles.contactInfo}>
            <span>Phone 2:</span>
            <span>{Phone2}</span>
          </div>
        ) : null}
        <div className={styles.contactInfo}>
          <span>Email:</span>
          <span>{Email}</span>
        </div>
      </div>
    );
  });

  return (
    <div className={styles.Contacts}>
      {cts}
    </div>
  );
}

export default Contacts;
