export const CLEAR_PUSHER = 'CLEAR_PUSHER';
export const TOGGLE_PUSHER = 'TOGGLE_PUSHER';
export const SHOW_PUSHER = 'SHOW_PUSHER';
export const HIDE_PUSHER = 'HIDE_PUSHER';
export const SET_PUSHER_MSG = 'SET_PUSHER_MSG';

export const clearPusherMsg = () => ({
  type: CLEAR_PUSHER,
});

export const togglePusher = () => ({
  type: TOGGLE_PUSHER,
});

export const setPusherMsg = (msg) => ({
  type: SET_PUSHER_MSG,
  msg,
});

export const showPusher = () => ({
  type: SHOW_PUSHER,
});

export const hidePusher = () => ({
  type: HIDE_PUSHER,
});
