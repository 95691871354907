import {
  CLEAR_PUSHER,
  TOGGLE_PUSHER,
  SHOW_PUSHER,
  HIDE_PUSHER,
  SET_PUSHER_MSG,
} from '../actions/pusherActions';

const defaultState = {
  show: false,
  msg: '',
};

const pusherReducer = (state = defaultState, action = {}) => {
  const updatedState = { ...state };
  switch (action.type) {
    case CLEAR_PUSHER:
      updatedState.show = false;
      updatedState.msg = '';
      break;
    case TOGGLE_PUSHER:
      updatedState.show = !state.show;
      break;
    case SHOW_PUSHER:
      updatedState.show = true;
      break;
    case HIDE_PUSHER:
      updatedState.show = false;
      break;
    case SET_PUSHER_MSG: {
      updatedState.msg = action.msg;
      break;
    }
    default: {
      return state;
    }
  }
  return updatedState;
};

export default pusherReducer;
