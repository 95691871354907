import React, {
  lazy,
  Suspense,
  useState,
  useEffect,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import 'sweetalert2/src/sweetalert2.scss';
import { Buffer } from 'buffer';

import { AuthRoute, ProtectedRoute } from './components/HOC/RouteUtils';
import { loadSettings, updateLocalStorageToken } from './utils';
import { receiveCurrentUser } from './actions';
import Loading from './components/shared/Loading';

import './App.scss';

// static components
import Nav from './components/static/Nav/Nav';
import Footer from './components/static/Footer/Footer';
import Pusher from './components/static/Pusher/Pusher';

// Installer portal
import SavedOrders from './components/Installer/SavedOrders/SavedOrders';
import ScanAsset from './components/shared/ScanAsset/ScanAsset';
import Search from './components/Installer/Search/Search';
import SavedAssets from './components/Installer/SavedAssets/SavedAssets';

const { REACT_APP_CURRENT_APP_VERSION } = process.env;

// shared components
const LoginPage = lazy(() => import('./components/LoginPage/LoginPage').catch(() => window.location.reload()));
const SuperLoginPage = lazy(() => import('./components/LoginPage/SuperLoginPage').catch(() => window.location.reload()));
const PortalSelect = lazy(() => import('./components/PortalSelect/PortalSelect').catch(() => window.location.reload()));
const AccountRecovery = lazy(() => import('./components/AccountRecovery/AccountRecovery').catch(() => window.location.reload()));
const Settings = lazy(() => import('./components/Settings/Settings').catch(() => window.location.reload()));

// RezBiz portal
const AccountInfo = lazy(() => import('./components/RezBiz/AccountInfo/AccountInfo').catch(() => window.location.reload()));
const Services = lazy(() => import('./components/RezBiz/Services/Services').catch(() => window.location.reload()));
const BillingCenter = lazy(() => import('./components/RezBiz/BillingCenter/BillingCenter').catch(() => window.location.reload()));
const Reports = lazy(() => import('./components/RezBiz/Reports/Reports').catch(() => window.location.reload()));

// Contractor portal
const ContractorHome = lazy(() => import('./components/Contractor/ContractorHome/ContractorHome').catch(() => window.location.reload()));
const WorkOrders = lazy(() => import('./components/Contractor/WorkOrders/WorkOrders').catch(() => window.location.reload()));
const Maps = lazy(() => import('./components/Contractor/Maps/Maps').catch(() => window.location.reload()));
const Calendar = lazy(() => import('./components/Contractor/Calendar/Calendar').catch(() => window.location.reload()));
const RestorationTickets = lazy(() => import('./components/Contractor/RestorationTickets/RestorationTickets').catch(() => window.location.reload()));

// Programming test portal
const ProgTestLogin = lazy(() => import('./components/ProgrammingTest/ProgTestLogin/ProgTestLogin').catch(() => window.location.reload()));
const ProgrammingTest = lazy(() => import('./components/ProgrammingTest/ProgrammingTest/ProgrammingTest').catch(() => window.location.reload()));

function App() {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.session);
  const sessionFromReact = JSON.stringify(currentUser);
  const [portalType, setPortalType] = useState('');

  const {
    nInstaller = 0,
    nContractor = 0,
    UNITYSUPERUSER = 0,
  } = currentUser;

  useEffect(() => {
    const token = window.token || updateLocalStorageToken();
    if (token) {
      const bufferObj = Buffer.from(token, 'base64');
      dispatch(receiveCurrentUser(JSON.parse(bufferObj.toString('utf8'))));
    } else {
      dispatch(receiveCurrentUser(currentUser));
    }
    document.title = `Portal v${REACT_APP_CURRENT_APP_VERSION}`;
    console.log(`Portal v${REACT_APP_CURRENT_APP_VERSION}`);
    if (document.getElementById('App')) {
      document.getElementById('cannotUpdateCache').classList.add('hidden');
    }
  }, []);

  useEffect(() => {
    loadSettings(sessionFromReact);
    if (UNITYSUPERUSER === 1 || nInstaller + nContractor === 0) {
      setPortalType('rezBiz');
    }
  }, [currentUser]);

  return (
    <div id="App" className="App">
      <Nav portalType={portalType} />
      <Pusher />
      <Suspense fallback={<Loading />}>
        <Switch>
          <AuthRoute path="/login/superUser/:vcCustomerID/:hash/:nEmployeeID" component={SuperLoginPage} />
          <AuthRoute path="/login" component={LoginPage} />
          <Route path="/accountRecovery" component={AccountRecovery} />
          <ProtectedRoute path="/portalSelect" component={PortalSelect} portalType={portalType} setPortalType={setPortalType} />
          <ProtectedRoute path="/settings" component={Settings} setPortalType={setPortalType} />

          {/* Rez, Biz and wholesale sites */}
          <ProtectedRoute path="/accountInfo" component={AccountInfo} />
          <ProtectedRoute path="/services" component={Services} />
          <ProtectedRoute path="/billingCenter" component={BillingCenter} />
          <ProtectedRoute path="/reports" component={Reports} />

          {/* contractor sites */}
          <ProtectedRoute path="/contractorHome" component={ContractorHome} />
          <ProtectedRoute path="/workOrders" component={WorkOrders} />
          <ProtectedRoute path="/calendar" component={Calendar} />
          <ProtectedRoute path="/restorationTickets" component={RestorationTickets} />
          <ProtectedRoute path="/scanAsset" component={ScanAsset} />
          <ProtectedRoute path="/maps/:params" component={Maps} />
          <ProtectedRoute path="/maps" component={Maps} />

          {/* installer sites */}
          <ProtectedRoute path="/search" component={Search} />
          <ProtectedRoute path="/savedWorkOrders" component={SavedOrders} />
          <ProtectedRoute path="/scanAsset" component={ScanAsset} />
          <ProtectedRoute path="/SavedAssets" component={SavedAssets} />

          {/* Programming test */}
          <Route path="/programmingTest" component={ProgTestLogin} />
          <Route path="/programmingTestPage" component={ProgrammingTest} />
          <Route path="/" component={LoginPage} />
        </Switch>
      </Suspense>
      <Footer />
    </div>
  );
}

export default App;
