import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';

// FIDd,CLLId,STRUCTURE_d,Latituded,Longitude
const str = `MLBGMOHT,Millersburg Hut - 4527 State Road J Fulton MO 65251,38.756935,-92.0972469
CLMAMOFW,Karen Cabinet - 2703 Clark Lane Columbia MO 65202,38.9721206722,-92.2056331578
CLMAMOFW,Clark Lane CO 2703 Clark Lane Columbia MO 65202,38.9645232227,-92.2963515166
FLTNMOHT,Fulton Hut - 4976 County Road 304 Fulton MO 65251,38.8476633671,-91.9754562075
CLMAMOXN,Boonedocs CO - 121 Albany Dr Columbia MO 65201,38.9524943643,-92.2893012903
CLMAMOXV,Thornbrook Cabinet - 4401 Thornbrook Ridge Columbia MO 65203,38.896155649,-92.401630559
CLMAMOXA,Main CO - 625 E Cherry St Columbia MO 65201,38.9506310122,-92.3302812262
CLMAMOXE,Smiley CO - 1307 Smiley Lane Columbia MO 65202,38.9932483284,-92.3198529068
CLMAMOCF,Elleta Hut - 904 Elleta Blvd Columbia MO 65202,38.974052584,-92.3260285208
CLMAMOXL,Limerick CO - 1909 Chapel Hill Rd Columbia MO 65203,38.9321241985,-92.369405974
CLMAMOXC,East CO - 5104 Mexico Gravel Rd Columbia MO 65202,38.9850918044,-92.2597921255
CLMAMOXO,Oakland CO - 2007 Sylvan Ln Columbia MO 65202,38.9721525073,-92.301343787
CLMAMOXI,Ice Chalet CO - 3412 Ponderosa St Columbia MO 65201,38.9124003086,-92.2994336776
CLMAMOXR,Rock Bridge CO - 3606 Monterey Columbia MO 65201,38.9117017201,-92.3385417167
CLMAMOXH,Highlands CO - 1001 Southampton Dr Columbia MO 65201,38.9014531472,-92.35448623
CLMAMOXB,West CO - 2811 W Broadway Columbia MO 65203,38.9549974352,-92.380377739
CLMAMOXV,Belleview CO - 4300 Southview Dr Columbia MO 65203,38.9184640859,-92.4003922431
JFCYMOXA,JC Main CO - 319 Madison St Jefferson City MO 65101,38.5752349572,-92.1715803563
JFCYMOXZ,JC West CO - 3621 Country Club Dr Jefferson City MO 65109,38.5833942163,-92.2542798291
MEXCMOJU,Mexico CO - 201 E Promenade St Mexico MO 65265,39.1715310531,-91.8813154942
FLTNMOMI,Fulton CO - 702 Market St Fulton MO 65251,38.8496929538,-91.9465574991
MBRLMOAM,Moberly CO - 225 W Coates St Moberly MO 65270,39.4210112142,-92.4387971532
SLIMO,Sedalia Sho-Me GFRC - 102 South State Fair Ave Sedalia MO 65301,38.7133056453,-93.2549655317
SDLIMOTA,Sealia CO - 220 E 5th St Sedalia MO 65301,38.7065823541,-93.2262344893
OFLNMOXA,O'Fallon CO - 8604 Veterans Memorial Parkway O Fallon MO 63366,38.7999945878,-90.6986224144
ASLDMOSKAC,Ashland Setters Cab - 6640 American Setter Dr Ashland MO 65010,38.7745801,-92.2410802
ASLDMOSKAC,Ashland Hidden Woods Cab - 15451 S Hidden Woods Ct Ashland MO 65010,38.7677516,-92.2889872
CENLMO,Centralia POP - 114 S Rollins St Centralia MO 65240,39.210649,-92.1395982
CHLCMOMI,Chillicothe CO - 501 Cherry St Chillicothe MO 64601,39.7926965,-93.5568482
CLTNMO,Clinton Cabinet - 105 E Ohio St Clinton MO 64735,38.3671098,-93.7778718
WRTNMO,Warrenton Cabinet - 14799 North St Hallsville MO 65255,39.1220375,-92.2349334
WRBGMO,Warrensburg CO - 210 E Market St Warrensburg MO 64093,38.7644666,-93.7391521
TROYMOX,Troy CO - 550 2nd St Troy MO 63379,38.9787303,-90.981679
KKVLMOMO,Kirksville CO - 216 E Washington St Kirksville MO 63501,40.1944945,-92.5834123
HLVLMO,Hallsville Cabinet - 14799 North St Hallsville MO 65255,39.1220375,-92.2349334
ROLLMO,Rolla POP - 109 E 12th St Rolla MO 65401,37.9533867,-91.7719501
FYTTMOPKAC1,Fayette Cabinet - 611 Herndon St Fayette MO 65248,39.1520970216d,-92.6930426119`;
const { google } = window;

const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

const getDistance = (
  lat1,
  lat2,
  lon1,
  lon2,
) => {
  // The math module contains a function
  // named toRadians which converts from
  // degrees to radians.
  const lon1Radian = (lon1 * Math.PI) / 180;
  const lon2Radian = (lon2 * Math.PI) / 180;
  const lat1Radian = (lat1 * Math.PI) / 180;
  const lat2Radian = (lat2 * Math.PI) / 180;

  // Haversine formula
  const dlon = lon2Radian - lon1Radian;
  const dlat = lat2Radian - lat1Radian;
  const a = Math.sin(dlat / 2) ** 2
  + Math.cos(lat1) * Math.cos(lat2)
  * Math.sin(dlon / 2) ** 2;

  const c = 2 * Math.asin(Math.sqrt(a));

  // Radius of earth.
  // use 6371 for kilometers
  // Use 3956 for miles
  const r = 3956;

  // calculate the result
  return c * r;
};

function Colocations() {
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  // Split initial string into line by line strings
  const coArray = str.split(/\n/i);
  const [sliceLength, setSliceLength] = useState(3);

  // Split each string into an array
  const indexCreator = (inputArray) => {
    const arrayInput = inputArray.split(',');
    // Function to create objects
    const [
      ,
      Location,
      Latitude,
      Longitude,
    ] = arrayInput;

    const fLat = parseFloat(Latitude, 10);
    const fLng = parseFloat(Longitude, 10);
    const Distance = getDistance(fLat, lat, fLng, lng);

    return {
      Location,
      Latitude,
      Longitude,
      Distance,
    };
  };

  // Split array into further arrays
  const mappedArray = coArray.map(indexCreator).sort((a, b) => a.Distance - b.Distance);
  // Initializes map with a default focus on Socket's Clark Lane Office
  const initMap = (pos) => {
    const { coords = {} } = pos; // coords: class GeolocationCoordinates
    setLat(coords.latitude || 38.9652567);
    setLng(coords.longitude || -92.2969987);
    const center = { lat: coords.latitude || 38.9652567, lng: coords.longitude || -92.2969987 };
    const map = new google.maps.Map(
      document.getElementById('colomap'),
      {
        zoom: 14,
        center,
        auth: 'AIzaSyCoCf5g8TBeq5Sxa2hDw8n9u5epEaLCFn8',
      },
    );
    // For loop to establish markers and links for each CO location and name
    mappedArray.forEach((loc) => {
      const { Latitude, Longitude, Location } = loc;
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(Latitude, Longitude),
        map,
        title: Location,
      });
      // Creates the link to provide google map directions on click of the marker
      marker.addListener('click', () => {
        const infowindow = new google.maps.InfoWindow({
          content: `${Location}, <br /><a href="http://maps.google.com/maps?q=loc:${Latitude},${Longitude}&navigate=yes" target="_blank"  rel="noreferrer">Click to Navigate</a>`,
        });
        infowindow.open(map, marker);
      });
    });
  };

  const renderCOList = () => {
    const list = mappedArray
      .slice(0, sliceLength)
      .map((loc) => {
        const {
          Location,
          Latitude,
          Longitude,
          Distance,
        } = loc;
        return (
          <li key={`${Latitude} ${Longitude}-li`}>
            {Location}
            <a href={`http://maps.google.com/maps?q=loc:${Latitude},${Longitude}&navigate=yes`} target="_blank" rel="noreferrer">{` ${Distance.toFixed(2)} miles away`}</a>
          </li>
        );
      });
    return (
      <ul className={styles.coloUl}>
        {list}
      </ul>
    );
  };

  const handleClickToggleCOList = () => {
    if (sliceLength === 3) {
      setSliceLength(mappedArray.length);
    } else {
      setSliceLength(3);
    }
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(initMap, initMap, options);
  }, []);

  return (
    <div className={styles.Colocations}>
      <button type="button" onClick={handleClickToggleCOList}>{`${sliceLength === 3 ? 'Expand' : 'Collapse'} CO List`}</button>
      {renderCOList()}
      <div id="colomap" className={styles.colomap} />
    </div>
  );
}

export default Colocations;
