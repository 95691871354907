import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Link,
  useLocation,
  useHistory,
} from 'react-router-dom';

import {
  rezBizLinks,
  contractorLinks,
  installerLinks,
  logout,
} from '../../../utils';
import HamburgerMenu from './HamburgerMenu/HamburgerMenu';

import styles from './styles.module.scss';

const signedOutNav = (
  <div className={styles.nav}>
    <Link to="/">
      <img src="/assets/images/logo.png" alt="logo" />
    </Link>
    <h2>Customer Service: 1-800-762-5383</h2>
  </div>
);

function Nav({ portalType }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { session } = useSelector((state) => state);
  const { currentUser = {} } = session;
  const signedIn = !!currentUser?.vcCustomerID;
  const { pathname } = useLocation();
  const onLogout = () => dispatch(logout());
  const [links, setLinks] = useState([]);

  useEffect(() => {
    switch (portalType) {
      case 'rezBiz':
        setLinks(rezBizLinks);
        break;
      case 'installer':
        setLinks(installerLinks);
        break;
      case 'contractor':
        setLinks(contractorLinks);
        break;
      default:
        setLinks([]);
        break;
    }
  }, [portalType]);

  useEffect(() => {
    const navBtns = document.getElementById('navBtns');
    if (!navBtns) {
      return;
    }
    const as = navBtns.getElementsByTagName('a');
    for (let i = 0; i < as.length; i += 1) {
      as[i].classList.remove('active');
    }
    for (let i = 0; i < as.length; i += 1) {
      // the href looks like: 'http://localhost:3000/#/accountInfo/subtab'
      const tabName = as[i].href.split('#/')[1].split('/')[0];
      if (pathname.includes(tabName)) {
        as[i].classList.add('active');
      }
    }
  }, [pathname]);

  const {
    vcFirstName,
    vcLastName,
    vcCustomerID,
    vcCustomerType,
    vcCustomerName,
  } = currentUser;
  const userName = `${vcFirstName} ${vcLastName}`;
  useEffect(() => {
    if (vcCustomerType !== 'Consumer' && rezBizLinks.length === 3) {
      rezBizLinks.push({ url: '/reports', title: 'Reports' });
    }
    if (['Consumer', 'Residential'].includes(vcCustomerType) && rezBizLinks.length === 4) {
      rezBizLinks.pop();
    }
  }, [vcCustomerType]);
  if (!signedIn) {
    return signedOutNav;
  }

  // when refreshing the page, the portalType gets lost
  if (vcCustomerType && !portalType && history.location.pathname !== '/portalSelect') {
    history.push('/portalSelect');
  }

  return (
    <div className={`${styles.navWrapper}`}>
      <HamburgerMenu links={links} />
      <div className={styles.nav}>
        <Link to="/">
          <img src="/assets/images/logo.png" alt="logo" />
        </Link>
        <div className={`${styles.userInfo}`}>
          <p>
            {'Welcome, '}
            <b>{userName}</b>
          </p>
          {vcCustomerType === 'Consumer' ? null : (
            <p>
              {'Account: '}
              <b>{vcCustomerName}</b>
            </p>
          )}
          <p>
            {'Account #: '}
            <b>{vcCustomerID}</b>
          </p>
        </div>
        <div className={styles.logout}>
          <h3>1-800-762-5383</h3>
          <a href="http://webmail.socket.net/" target="_blank" rel="noreferrer">Webmail</a>
          <Link to="/settings">Settings</Link>
          <Link to="/login" onClick={onLogout}>Log out</Link>
        </div>
      </div>
    </div>
  );
}

export default Nav;
