import $ from 'jquery';
import { cacheResponseHelper } from './helpers';
import { BACKEND_API } from './constants';

const postToSettingsBack = (data) => cacheResponseHelper(data, `${BACKEND_API}/backend/functions/settingsBack/settingsBack.php`);

const setTheme = (themeObj) => {
  if (themeObj.enableStyle) {
    document.getElementById('body').style = `background: linear-gradient(${themeObj.gradientDirection}deg, ${themeObj.selectedColor2} 0%, ${themeObj.selectedColor1} 100%); `;
    document.getElementById('App').classList.add('boxShadow');
  } else {
    document.getElementById('body').style = 'background: white;';
    document.getElementById('App').classList.remove('boxShadow');
  }
  localStorage.setItem('themeObj', JSON.stringify(themeObj));
};

export const uploadSettings = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    ...formData,
    UTILITYID: 59,
  };
  return postToSettingsBack(data);
};

export const loadSettings = (sessionFromReact) => {
  const userID = JSON.parse(sessionFromReact).nUserID;
  if (userID === '3260') {
    // If superuser
    const themeObj = localStorage.getItem('themeObj');
    if (!themeObj) {
      return;
    }
    const parsed = JSON.parse(themeObj);
    setTheme(parsed);
    return;
  }

  const data = {
    sessionFromReact,
    action: 'load',
  };
  $.ajax({
    url: `${BACKEND_API}/backend/functions/settingsBack/settingsBack.php`,
    method: 'POST',
    data,
    success: (response) => {
      if (!response) {
        return;
      }
      const responseObj = JSON.parse(response);
      const themeObj = JSON.parse(responseObj.vcTheme);
      setTheme(themeObj);
    },
    error: (e) => console.log(e),
  });
};
