import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import { logout } from '../../../../utils';

import styles from './styles.module.scss';

function HamburgerMenu(props) {
  const { links } = props;
  const dispatch = useDispatch();
  const onLogout = () => dispatch(logout());
  const { pathname } = useLocation();

  const toggleHamburger = () => {
    const elementExists = document.getElementById('navBtn');
    if (elementExists) {
      document.getElementById('navBtn').classList.toggle(styles.expandHamburger);
    }
  };

  const renderLinks = () => {
    const linkElements = links.map((link) => {
      const { url, title } = link;
      const active = pathname.includes(url) ? styles.active : '';
      return (
        <Link to={url} key={`${url}-${title}-link`} onClick={() => toggleHamburger()} className={active}>{title}</Link>
      );
    });
    return (
      <div className={styles.navBtns} id="navBtn">
        {linkElements}
        <Link to="login" key="logout-link" onClick={onLogout} className={styles.hamburgerLogout}>Log out</Link>
      </div>
    );
  };

  return (
    <div className={styles.dropdownWrapper} data-cy="navbar">
      <div className={styles.hamburgerWrapper}>
        <div role="button" tabIndex="0" className={styles.hamburgerNavItem} onClick={() => toggleHamburger()} onKeyDown={() => toggleHamburger()}>
          <FontAwesomeIcon icon={faBars} />
        </div>
        {renderLinks()}
      </div>
    </div>
  );
}

export default HamburgerMenu;
