import React, { useState, useEffect, useContext } from 'react';
import Swal from 'sweetalert2';

import WorkOrderContext from '../WorkOrderContext';

const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

function Directions() {
  const { WOObj } = useContext(WorkOrderContext);
  const [coords, setCoords] = useState(null);

  const onSuccess = (pos) => {
    setCoords(pos.coords);
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(onSuccess, (text) => {
      Swal.fire({
        text,
        icon: 'warning',
      });
    }, options);
  }, []);

  useEffect(() => {
    if (!coords) {
      return;
    }
    const destinationQuery = `${WOObj.ServiceLocationDetails?.Latitude},${WOObj.ServiceLocationDetails?.Longitude}`;
    window.open(`http://maps.google.com/maps?q=loc:${destinationQuery}&navigate=yes`);
  }, [coords]);

  return (
    <div />
  );
}

export default Directions;
