import $ from 'jquery';
import { Buffer } from 'buffer';

import { logoutUser, recieveSessionErrors } from '../actions';
import { BACKEND_API } from './constants';

const postToSessionBack = (data) => $.ajax({
  url: `${BACKEND_API}/backend/functions/sessionBack/sessionBack.php`,
  method: 'POST',
  data,
  success: (response) => response,
  error: (error) => error,
});

export const superLogin = (vcCustomerID, hash, nEmployeeID, light) => {
  const data = {
    vcCustomerID,
    hash,
    nEmployeeID,
    light,
    action: 'superLogin',
  };
  return postToSessionBack(data);
};

export const login = (user, light = false) => {
  const data = {
    ...user,
    action: 'login',
    light,
  };
  return postToSessionBack(data);
};

export const register = (user) => {
  const data = {
    ...user,
    action: 'register',
  };
  return postToSessionBack(data);
};

export const logout = (error) => (dispatch) => {
  // Remove the token from local storage
  localStorage.removeItem('token');
  // Dispatch a logout action
  dispatch(logoutUser());
  if (error?.statusText) {
    dispatch(recieveSessionErrors(error.statusText.replaceAll(/\r|\t|\n/gm, '')));
  }
};
const formatTime = (time) => time.toString().padStart(2, '0');

export const updateLocalStorageToken = () => {
  const token = localStorage.getItem('token');
  if (!token) {
    return null;
  }
  let bufferObj = Buffer.from(token, 'base64');
  const decoded = JSON.parse(bufferObj.toString('utf8'));
  const date = new Date();
  // format to '2021-02-19 16:01:58', aka 'Y-m-d H:i:s'
  // make token valid for a year. The getMonth is index so handle the numerical value manually
  decoded.last_touched = `${date.getFullYear() + 1}-${date.getMonth() + 1}-${date.getDate()} ${formatTime(date.getUTCHours())}:${formatTime(date.getMinutes())}:${formatTime(date.getSeconds())}`;
  const originalString = JSON.stringify(decoded);
  bufferObj = Buffer.from(originalString, 'utf8');
  const encoded = bufferObj.toString('base64');
  localStorage.setItem('token', encoded);
  return encoded;
};
