import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom';

function Auth(props) {
  const {
    component: Component,
    path,
    session,
    exact,
  } = props;
  // super user log in is special, we need to override the log in status
  const signedIn = Boolean(session.currentUser?.vcCustomerID && !path.includes('superUser'));
  return (
    <Route
      path={path}
      exact={exact}
      // eslint-disable-next-line react/jsx-props-no-spreading
      render={() => (signedIn ? <Redirect to="/portalSelect" push /> : <Component {...props} />)}
    />
  );
}

function Protected(props) {
  const {
    component: Component,
    path,
    session,
    exact,
  } = props;
  const signedIn = Boolean(session.currentUser?.vcCustomerID && !path.includes('superUser'));
  return (
    <Route
      path={path}
      exact={exact}
      // eslint-disable-next-line react/jsx-props-no-spreading
      render={() => (signedIn ? <Component {...props} /> : <Redirect to="/login" push />)}
    />

  );
}

const mapStateToProps = (state) => state;

export const AuthRoute = withRouter(
  connect(mapStateToProps, null)(Auth),
);

export const ProtectedRoute = withRouter(
  connect(mapStateToProps, null)(Protected),
);
