import $ from 'jquery';
import { BACKEND_API } from './constants';

const postToBillsBack = (data) => $.ajax({
  method: 'POST',
  url: `${BACKEND_API}/backend/functions/billsBack/billsBack.php`,
  data,
});

export const getBills = (sessionFromReact) => {
  const data = {
    sessionFromReact,
    action: 'getBills',
    UTILITYID: 2,
  };
  return postToBillsBack(data);
};

export const payBills = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    ...formData,
    action: 'payBills',
    UTILITYID: 13,
  };
  return postToBillsBack(data);
};

export const viewHistory = (sessionFromReact, MailingLocationID) => {
  const data = {
    sessionFromReact,
    action: 'viewHistory',
    MailingLocationID,
    UTILITYID: 12,
  };
  return postToBillsBack(data);
};

export const downloadInvoiceCSV = (sessionFromReact, nInvoiceGroupID) => {
  const data = {
    sessionFromReact,
    nInvoiceGroupID,
    action: 'downloadInvoiceCSV',
    UTILITYID: 67,
  };
  return postToBillsBack(data);
};

export const downloadInvoicePDF = (sessionFromReact, nInvoiceID) => {
  const data = {
    sessionFromReact,
    nInvoiceID,
    action: 'downloadInvoicePDF',
    UTILITYID: 16,
  };
  return postToBillsBack(data);
};

export const viewAutopay = (sessionFromReact, nActiveMailingLocation) => {
  const data = {
    sessionFromReact,
    action: 'viewAutopay',
    nActiveMailingLocation,
    UTILITYID: 14,
  };
  return postToBillsBack(data);
};

export const addPaymentMethod = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    ...formData,
    action: 'addPaymentMethod',
    UTILITYID: 14,
  };
  return postToBillsBack(data);
};

// nMailingLocationID is used for wholesale accounts
export const getMailingLocationObject = (sessionFromReact, MailingLocationID) => {
  const data = {
    sessionFromReact,
    action: 'getMailingLocationObject',
    MailingLocationID,
    UTILITYID: 15,
  };
  return postToBillsBack(data);
};

export const deletePaymentMethod = (sessionFromReact, nPaymentID) => {
  const data = {
    sessionFromReact,
    action: 'deletePaymentMethod',
    nPaymentID,
    UTILITYID: 14,
  };
  return postToBillsBack(data);
};

export const deactivateAutopay = (sessionFromReact, nPaymentID) => {
  const data = {
    sessionFromReact,
    action: 'deactivateAutopay',
    nPaymentID,
    UTILITYID: 14,
  };
  return postToBillsBack(data);
};

export const activateAutopay = (sessionFromReact, nPaymentID, nLocationID) => {
  const data = {
    sessionFromReact,
    action: 'activatePaymentMethod',
    nPaymentID,
    nLocationID,
    UTILITYID: 14,
  };
  return postToBillsBack(data);
};

export const zipCodeLookUp = (sessionFromReact, vcZip) => {
  const data = {
    sessionFromReact,
    action: 'zipCodeLookUp',
    vcZip,
    UTILITYID: 38,
  };
  return postToBillsBack(data);
};

export const updateAddress = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'updateAddress',
    ...formData,
    UTILITYID: 38,
  };
  return postToBillsBack(data);
};

export const getTroubleTickets = (sessionFromReact, ServiceLocationID) => {
  const data = {
    sessionFromReact,
    action: 'getTroubleTickets',
    ServiceLocationID,
    UTILITYID: 4,
  };
  return postToBillsBack(data);
};

export const submitNewTroubleTicket = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    ...formData,
    action: 'submitNewTroubleTicket',
    UTILITYID: 4,
  };
  return postToBillsBack(data);
};
