import $ from 'jquery';

import { BACKEND_API } from './constants';

const postToServiceBack = (data) => $.ajax({
  method: 'POST',
  url: `${BACKEND_API}/backend/functions/servicesBack/servicesBack.php`,
  data,
});

export const submitAccountForm = (sessionFromReact, formData) => {
  // action specified in the form
  const data = {
    sessionFromReact,
    ...formData,
    UTILITYID: 21,
  };
  return postToServiceBack(data);
};

export const modEmailPass = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    ...formData,
    action: 'modEmailPass',
    UTILITYID: 20,
  };
  return postToServiceBack(data);
};

export const modVacMsg = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    ...formData,
    action: 'modVacMsg',
    UTILITYID: 20,
  };
  return postToServiceBack(data);
};

export const modForward = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    ...formData,
    action: 'modForward',
    UTILITYID: 20,
  };
  return postToServiceBack(data);
};

export const getEmailObject = (sessionFromReact, AccountID, nServiceLocationID) => {
  const data = {
    sessionFromReact,
    AccountID,
    nServiceLocationID,
    action: 'getEmailObject',
    UTILITYID: 20,
  };
  return postToServiceBack(data);
};

export const getServiceLocationAllObjects = (sessionFromReact) => {
  const data = {
    sessionFromReact,
    action: 'getServiceLocationAllObjects',
    UTILITYID: 53,
  };
  return postToServiceBack(data);
};

export const getServiceLocationObject = (sessionFromReact, ServiceLocationID) => {
  const data = {
    sessionFromReact,
    action: 'getServiceLocationObject',
    UTILITYID: 53,
    ServiceLocationID,
  };
  return postToServiceBack(data);
};

export const getWholesaleServiceAll = (sessionFromReact, nMasterServiceID) => {
  const data = {
    sessionFromReact,
    action: 'getWholesaleServiceAll',
    UTILITYID: 53,
    nMasterServiceID,
  };
  return postToServiceBack(data);
};

export const getFaxAccounts = (sessionFromReact, ServiceLocationID) => {
  const data = {
    sessionFromReact,
    action: 'getFaxAccounts',
    UTILITYID: 49,
    ServiceLocationID,
  };
  return postToServiceBack(data);
};

export const getOutbox = (sessionFromReact, ServiceLocationID) => {
  const data = {
    sessionFromReact,
    action: 'getOutbox',
    UTILITYID: 49,
    ServiceLocationID,
  };
  return postToServiceBack(data);
};

export const downloadFax = (sessionFromReact, FaxID, FileID) => {
  const data = {
    sessionFromReact,
    action: 'downloadFax',
    UTILITYID: 49,
    FaxID,
    FileID,
  };
  return postToServiceBack(data);
};

export const getExistingCustomerObject = (sessionFromReact, ShoppingCart_nServiceLocationID) => {
  const data = {
    sessionFromReact,
    action: 'getExistingCustomerObject',
    UTILITYID: 49,
    ShoppingCart_nServiceLocationID,
  };
  return postToServiceBack(data);
};

export const getServiceAllFromLocationId = (sessionFromReact, ShoppingCart_nServiceLocationID) => {
  const data = {
    sessionFromReact,
    action: 'getServiceAllFromLocationId',
    UTILITYID: 49,
    ShoppingCart_nServiceLocationID,
  };
  return postToServiceBack(data);
};

export const verifyAddressSmartyStreets = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'verifyAddressSmartyStreets',
    UTILITYID: 49,
    ...formData,
  };
  return postToServiceBack(data);
};

export const getServicesOrdersAvailableServices = (sessionFromReact) => {
  const data = {
    sessionFromReact,
    action: 'getServicesOrdersAvailableServices',
    UTILITYID: 49,
  };
  return postToServiceBack(data);
};

export const addChangeOrder = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'addChangeOrder',
    UTILITYID: 49,
    ...formData,
  };
  return postToServiceBack(data);
};

export const addtempPackageSalesOrder = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'addtempPackageSalesOrder',
    UTILITYID: 49,
    ...formData,
  };
  return postToServiceBack(data);
};

export const getWholesalePendingSalesorders = (sessionFromReact) => {
  const data = {
    sessionFromReact,
    action: 'getWholesalePendingSalesorders',
    UTILITYID: 49,
  };
  return postToServiceBack(data);
};

export const getCityStates = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'getCityStates',
    UTILITYID: 49,
    ...formData,
  };
  return postToServiceBack(data);
};

export const getSmartyMatchData = (sessionFromReact, ResultID) => {
  const data = {
    sessionFromReact,
    action: 'getSmartyMatchData',
    UTILITYID: 49,
    ResultID,
  };
  return postToServiceBack(data);
};

export const getGeoCode = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'getGeoCode',
    UTILITYID: 49,
    ...formData,
  };
  return postToServiceBack(data);
};

export const checkCallawayCounty = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'checkCallawayCounty',
    UTILITYID: 49,
    ...formData,
  };
  return postToServiceBack(data);
};

export const checkColoArea = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'checkColoArea',
    UTILITYID: 49,
    ...formData,
  };
  return postToServiceBack(data);
};

export const getZipLookup = (sessionFromReact, zip) => {
  const data = {
    sessionFromReact,
    action: 'getZipLookup',
    UTILITYID: 49,
    zip,
  };
  return postToServiceBack(data);
};

export const getGilesAddressCheck = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'getGilesAddressCheck',
    UTILITYID: 49,
    ...formData,
  };
  return postToServiceBack(data);
};
export const getGilesTempAddressCheck = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'getGilesTempAddressCheck',
    UTILITYID: 49,
    ...formData,
  };
  return postToServiceBack(data);
};

export const getTempServiceLocationObject = (sessionFromReact, ServiceLocationID) => {
  const data = {
    sessionFromReact,
    action: 'getTempServiceLocationObject',
    UTILITYID: 49,
    ServiceLocationID,
  };
  return postToServiceBack(data);
};

export const getTempServiceLocationTempPackages = (sessionFromReact, ServiceLocationID) => {
  const data = {
    sessionFromReact,
    action: 'getTempServiceLocationTempPackages',
    UTILITYID: 49,
    ServiceLocationID,
  };
  return postToServiceBack(data);
};

export const getShoppingcartPackages = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'getShoppingcartPackages',
    UTILITYID: 49,
    ...formData,
  };
  return postToServiceBack(data);
};

export const getPackageNamePortal = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'getPackageNamePortal',
    UTILITYID: 49,
    ...formData,
  };
  return postToServiceBack(data);
};

export const getPackageGroups = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'getPackageGroups',
    UTILITYID: 49,
    ...formData,
  };
  return postToServiceBack(data);
};

export const getTempPackageObject = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'getTempPackageObject',
    UTILITYID: 49,
    ...formData,
  };
  return postToServiceBack(data);
};

export const addNewTempServiceLocation = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'addNewTempServiceLocation',
    UTILITYID: 49,
    ...formData,
  };
  return postToServiceBack(data);
};

export const savePackage = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'savePackage',
    UTILITYID: 49,
    ...formData,
  };
  return postToServiceBack(data);
};

export const getExistingServiceLocationTempPackages = (sessionFromReact, ServiceLocationID) => {
  const data = {
    sessionFromReact,
    action: 'getExistingServiceLocationTempPackages',
    UTILITYID: 49,
    ServiceLocationID,
  };
  return postToServiceBack(data);
};

export const automatedCheckout = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'automatedCheckout',
    UTILITYID: 49,
    ...formData,
  };
  return postToServiceBack(data);
};

export const loadAutomatedCheckoutVerification = (sessionFromReact, nTempPackageID) => {
  const data = {
    sessionFromReact,
    action: 'loadAutomatedCheckoutVerification',
    UTILITYID: 49,
    nTempPackageID,
  };
  return postToServiceBack(data);
};

export const submitCancelOrder = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'submitCancelOrder',
    UTILITYID: 49,
    ...formData,
  };
  return postToServiceBack(data);
};

export const getFaxToEmailUserAccounts = (sessionFromReact, ServiceLocationID) => {
  const data = {
    sessionFromReact,
    ServiceLocationID,
    action: 'getFaxToEmailUserAccounts',
    UTILITYID: 49,
  };
  return postToServiceBack(data);
};

// sending formData will be treated differently from other requests due to the file upload
export const validateFaxForm = (formData) => $.ajax({
  url: `${BACKEND_API}/backend/functions/servicesBack/servicesBack.php`,
  method: 'POST',
  success: (response) => response,
  error: (error) => error,
  type: 'POST',
  data: formData,
  processData: false,
  contentType: false,
});

export const queueFax = (sessionFromReact, AccountID, FaxID) => {
  const data = {
    sessionFromReact,
    AccountID,
    FaxID,
    action: 'queueFax',
    UTILITYID: 49,
  };
  return postToServiceBack(data);
};

export const addFaxToEmailAccountEmail = (sessionFromReact, AccountID, NewEmail) => {
  const data = {
    sessionFromReact,
    AccountID,
    NewEmail,
    action: 'addFaxToEmailAccountEmail',
    UTILITYID: 49,
  };
  return postToServiceBack(data);
};

export const removeFaxToEmailAccountEmail = (sessionFromReact, AccountID, EditEmail) => {
  const data = {
    sessionFromReact,
    AccountID,
    EditEmail,
    action: 'removeFaxToEmailAccountEmail',
    UTILITYID: 49,
  };
  return postToServiceBack(data);
};

export const getFirestickCredentials = (vcCustomerID) => $.ajax({
  url: 'http://inside.sockettelecom.com/functions/iptv/getCredentialsFromCustomerId.php',
  method: 'POST',
  data: { vcCustomerID },
  success: (response) => response,
  error: (error) => error,
});

export const getAuthCodes = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    ...formData, // actually only need pg as parameter but the component is shared so we need to pass a form
    action: 'getAuthCodes',
    UTILITYID: 49,
  };
  return postToServiceBack(data);
};

export const getPendingAuthCodes = (sessionFromReact, pg) => {
  const data = {
    sessionFromReact,
    pg,
    action: 'getPendingAuthCodes',
    UTILITYID: 49,
  };
  return postToServiceBack(data);
};

export const editAuthCodes = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    ...formData,
    action: 'editAuthCodes',
    UTILITYID: 49,
  };
  return postToServiceBack(data);
};

export const getSubscriberLists = (sessionFromReact) => {
  const data = {
    sessionFromReact,
    action: 'getSubscriberLists',
    UTILITYID: 49,
  };
  return postToServiceBack(data);
};

export const searchAuthCodes = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'searchAuthCodes',
    ...formData,
    UTILITYID: 48,
  };
  return postToServiceBack(data);
};

export const addAuthCode = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    action: 'addAuthCode',
    ...formData,
    UTILITYID: 48,
  };
  return postToServiceBack(data);
};

// sending formData will be treated differently from other requests due to the file
export const addAuthCodeList = (data) => $.ajax({
  url: `${BACKEND_API}/backend/functions/servicesBack/servicesBack.php`,
  method: 'POST',
  success: (response) => response,
  error: (error) => error,
  type: 'POST',
  data,
  processData: false,
  contentType: false,
});

export const getBusinessCenterRequestTicketCategories = (sessionFromReact) => {
  const data = {
    sessionFromReact,
    action: 'getBusinessCenterRequestTicketCategories',
    UTILITYID: 48,
  };
  return postToServiceBack(data);
};

export const createBusinessCenterRequestTicket = (sessionFromReact, formData) => {
  const data = {
    sessionFromReact,
    ...formData,
    action: 'createBusinessCenterRequestTicket',
    UTILITYID: 48,
  };
  return postToServiceBack(data);
};

export const getBusinessCenterRequestTickets = (sessionFromReact, MailingLocationID) => {
  const data = {
    sessionFromReact,
    MailingLocationID,
    action: 'getBusinessCenterRequestTickets',
    UTILITYID: 48,
  };
  return postToServiceBack(data);
};
